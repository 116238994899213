<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="campain-details-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1">
                  <button class="btn back pl20" (click)="goToBack()">
                    Back To Home Page
                  </button>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                  <button
                    class="btn-primary-sm btn-blue shine summary-report"
                    (click)="exportReport()"
                  >
                    <img
                      src="../../../assets/images/export-icon.png"
                      alt="export-icon"
                    />
                    <span class="ml10">Summary Report</span>
                  </button>
                  <input
                    type="file"
                    #file
                    hidden
                    (change)="handleFileInput($event)"
                  />
                  <button
                    (click)="file.click()"
                    class="btn-primary-sm btn-blue ml20 shine"
                    [disabled]="!campaign?.is_active"
                  >
                    Import Customer
                  </button>
                  <button
                    class="btn-primary-sm btn-red ml20 shine export-customer"
                    (click)="exportCustomer()"
                    disabled
                  >
                    <span>Export Customer</span>
                  </button>
                  <button
                    class="btn-primary-sm btn-grey ml20 shine"
                    (click)="editCampaign()"
                  >
                    Edit
                  </button>
                  <button
                  class="btn-primary-sm btn-blue ml20 shine"
                  (click)="redirectToUploadHistory()"
                >
                  Upload History
                </button>
                </div>
              </div>
              <div class="d-flex flex-wrap mt20 staus-strip">
                <div class="flex-grow-1">
                  <div class="d-flex status-div">
                    <p>Status :</p>
                    <label class="switch ml10">
                      <input
                        type="checkbox"
                        [checked]="campaign?.is_active"
                        disabled
                      />
                      <span class="slider round"></span>
                    </label>
                    <p class="ml10 active">
                      {{ campaign?.is_active ? "Active" : "Inactive" }}
                    </p>
                  </div>
                </div>
                <div class="flex-grow-1 d-flex justify-content-end">
                  <button class="btn-template">
                    <a [href]="url">UPLOADING TEMPLATE</a>
                  </button>
                  <div class="record-count">
                    Total Record Count : <span class="pl10">{{ count }}</span>
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-wrap justify-content-between mt20 campain-details-parent"
              >
                <div class="left-div">
                  <div class="campain-name">
                    <img
                      class="w-100 border-radius"
                      [src]="
                        campaign?.campaign_image
                          ? campaign.campaign_image
                          : '../../../assets/images/upload-campaign-sample-img.png'
                      "
                      alt="upload-campaign"
                    />
                    <h1 class="mt20">{{ campaign?.campaign_name }}</h1>
                    <p>
                      {{ campaign?.campaign_message }}
                    </p>
                  </div>
                </div>
                <div class="right-div">
                  <div class="campain-details">
                    <h1>Custom Fields Details (if any)</h1>
                    <div
                      class="filelds-table mt10"
                      *ngFor="
                        let customField of campaign?.tbl_campaign_customfields;
                        let i = index
                      "
                    >
                      <div class="row pt20">
                        <div class="col-6">
                          <label>CustomField {{ i + 1 }}</label>
                        </div>
                        <div class="col-6 text-break">
                          <label>{{ customField.field_name }}</label>
                        </div>
                      </div>
                    </div>

                    <h1 class="mt20">Priority to show for filter display</h1>
                    <div
                      class="priority-to-show"
                      *ngFor="let campaignPriority of campaignPriorities"
                    >
                      <span class="d-block pt20">
                        Priority {{ campaignPriority.priority_name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div></div>
<div class="progress-loader" *ngIf="!isUploading">
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      style="width: 100%"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div *ngIf="progressPercentage === 0">{{"Data is currently being processed."}}</div>
      <div *ngIf="progressPercentage > 0">{{progressPercentage}} {{" % of the upload process is complete. Please wait..."}}</div>
      
    </div>
  </div>
</div>
