import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login.service';
import { environment as env } from '../../environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  token: any;
  campaigns: any;
  data: any;
  total: number = 0;
  page: number = 1;
  pageSize = 6;
  enableUserDropdown = false;
  headers: any;
  jsonData: any;
  fileName: any;
  filterForm: any;
  submittedCampaignForm: boolean = false;
  showDateErrorMsg: boolean = false;
  passwordNotMaching: boolean = false;
  constructor(
    private apiService: LoginService,
    private router: Router,
    private toaster: ToastrService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getCampaigns();
    }
    this.filterForm = this.formBuilder.group({
      inactive: [null],
      active: [null],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  get campaignFormVal() {
    return this.filterForm.controls;
  }
  url = env.url;
  // logOut() {
  //   this.apiService.logout().subscribe((res: any) => {
  //     this.router.navigateByUrl('/login');
  //   });
  //   localStorage.clear();
  // }
  searchKey: any = '';
  searchData(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    this.apiService
      .search('campaign', this.searchKey, this.pageSize, this.page, this.token)
      .subscribe(
        (res: any) => {
          if (res['status'] == 'success') {
            this.campaigns = res['result'].data;
            this.total = res['result'].count;
          }
        },
        (err) => {
          this.toaster.error(err.error.message);
        }
      );
  }

  isShown: boolean = false; // hidden by default

  toggleShow() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isShown = !this.isShown;
    } else {
    }
  }

  getCampaigns() {
    let url = `campaign?allCampaign=true&limit=${this.pageSize}&page=${this.page}`;
    this.apiService.getCampaignList(url).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.campaigns = res['result'].capData;
          this.total = res['result'].count;
        }
      },
      (err) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  getCampaignId(id: any) {
    let data = {
      data: id,
    };
    this.router.navigate(['/campaign-detail', data]);
  }
  addCampaign() {
    this.router.navigate(['/add-campaign-detail']);
  }
  pageChangeEvent(event: number) {
    this.page = event;
    if (this.searchKey) {
      this.searchData();
    } else if (this.filterForm.valid) {
      this.saveFilter();
    } else {
      this.getCampaigns();
    }
  }
  showDropDown() {
    this.enableUserDropdown = !this.enableUserDropdown;
  }
  hideDropDown() {
    this.filterForm.reset();
    this.submittedCampaignForm = false;
    this.enableUserDropdown = false;
    this.getCampaigns();
  }
  OkButton(){
    this.filterForm.reset();
    this.submittedCampaignForm = false;
    this.enableUserDropdown = false;
    this.getCampaigns();
  }
  onEndDateChanged(event: any) {
    let startDate = moment(this.filterForm.value.startDate);
    let endDate = moment(event);
    console.log(startDate.diff(endDate, 'days')); // => 1// => -1
    if (startDate.diff(endDate, 'days') > 1) {
      this.passwordNotMaching = true;
      this.filterForm.controls['endDate'].setValue('');
      alert(`end date should  be greater than start date`);
    } else {
      this.passwordNotMaching = false;
    }
  }
  saveFilter(resetPage = false) {
    this.submittedCampaignForm = true;
    let url = `campaign?allCampaign=true&limit=${this.pageSize}&page=${this.page}
    &startDate=${this.filterForm.value.startDate}&endDate=${this.filterForm.value.endDate}`;
    if (this.filterForm.invalid) {
      return;
    } else {
      if (resetPage) {
        this.page = 1;
      }
      if (this.filterForm.value.active && !this.filterForm.value.inactive) {
        url = url + `&active=true`;
      } else if (
        this.filterForm.value.inactive &&
        !this.filterForm.value.active
      ) {
        url = url + `&inactive=true`;
      } else if (
        this.filterForm.value.inactive &&
        this.filterForm.value.active
      ) {
        url = url + `&inactive=true&active=true`;
      }
      this.apiService.getCampaignList(url).subscribe(
        (res: any) => {
          if (res['status'] == 'success') {
            this.campaigns = res['result'].capData;
            this.total = res['result'].count;
            this.jsonData = res['result'].capData;
            this.headers = Object.keys(res['result'].capData[0]);
            this.fileName = `filter`;
          }
        },
        (err) => {
          this.toaster.error(`There is error while filtering..`);
        }
      );
      this.submittedCampaignForm = false;
    }
    // alert("Fliter saved")
    this.enableUserDropdown = false;
  }
  download() {
    this.apiService.downloadFile(this.jsonData, this.fileName, this.headers);
  }
}
