<div class="top-bar w-100 sticky-top">
  <button (click)="toggleShow()" class="hamburger-menu"></button>
  <div class="mobile-logo" (click)="toggleShow()">
    <img src="../../assets/images/logo-white.png" />
  </div>
  <div class="logout mr20" (click)="logOut()">Logout</div>
</div>

<div
  *ngIf="mobileOnly"
  class="left-panel"
  id="test"
  [ngClass]="isShown ? 'menuOpen' : 'menuClose'"
>
  <img
    class="close-icon"
    (click)="toggleShow()"
    src="../../assets/images/close-icon.png"
  />
  <div class="logo d-flex align-items-center justify-content-center">
    <img src="../../assets/images/logo-white.png" />
  </div>
  <div class="pl20 pr30 pt40">
    <div
      class="nav flex-column nav-pills text-align-left"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
      (click)="toggleShow()"
    >
      <button
        routerLink="/campaign"
        class="nav-link"
        id="v-pills-campaign-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-campaign"
        type="button"
        role="tab"
        aria-controls="v-pills-campaign"
        aria-selected="fasle"
        [ngClass]="activateCampaign ? 'active' : 'inactive'"
      >
        <span class="nav-icon"
          ><img src="../../assets/images/campaign-icon.png"
        /></span>
        <span>Campaign</span>
      </button>
      <button
        class="nav-link"
        routerLink="/staff-list"
        id="v-pills-staff-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-staff"
        type="button"
        role="tab"
        aria-controls="v-pills-staff"
        aria-selected="false"
        [ngClass]="activateStaff ? 'active' : 'inactive'"
      >
        <span class="nav-icon"
          ><img src="../../assets/images/staff-icon.png"
        /></span>
        <a>ID Creation</a>
      </button>
      <button
        class="nav-link"
        id="v-pills-store-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-store"
        type="button"
        role="tab"
        aria-controls="v-pills-store"
        aria-selected="false"
        [ngClass]="activateStore ? 'active' : 'inactive'"
      >
        <span class="nav-icon"
          ><img src="../../assets/images/store-icon.png"
        /></span>
        <a>Store</a>
      </button>
      <!-- <button
        routerLink="/priority-list"
        class="nav-link"
        id="v-pills-priority-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-priority"
        type="button"
        role="tab"
        aria-controls="v-pills-priority"
        aria-selected="false"
        [ngClass]="activatePriority ? 'active' : 'inactive'"
      >
        <span class="nav-icon"
          ><img src="../../assets/images/priority-icon.png"
        /></span>
        <span>Priority</span>
      </button> -->
    </div>
  </div>
</div>
