import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { UntypedFormControl } from '@angular/forms';
@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss'],
})
export class AddStaffComponent implements OnInit {
  staffForm: any;
  stores: any;
  errorMsg: any;
  token: any;
  constructor(
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private router: Router
  ) {}
  isShown: boolean = false; // hidden by default
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getStores();
    }
    this.staffForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: [''],
      mobile: ['', Validators.required],
      store_id: [''],
      is_admin:false
    });
    // listen for search field value changes
    this.storeFilterCtrl.valueChanges.subscribe(() => {
      this.storeFilterCtrl.value;
      this.filterStores();
    });
  }
  getStores() {
    this.apiService.getStoreListStaff(true, this.token).subscribe(
      (res: any) => {
        if (res['status'] === 'success') {
          this.stores = res['result'].data;
        }
      },
      (err) => {
        this.toaster.error(`${err.message}`);
      }
    );
  }
  saveStaff() {
    console.log(this.storeCtrl.value);
    this.staffForm.controls['store_id'].setValue(this.storeCtrl.value);
    this.apiService.addStaff(this.staffForm.value, this.token).subscribe(
      (res: any) => {
        if (res['status'] === 'success') {
          this.toaster.success(`${res['result']}`);
          this.goToStaff();
        }else{
          console.log(res,"in else")
          this.toaster.error(`${res.error?.['message']}`);
        }
      },
      (err) => {
        this.toaster.error(`${err.error.message}`);
      }
    );
  }
  goToStaff() {
    this.router.navigate(['/staff-list']);
  }
  onlyNumbers(ev: any) {
    this.errorMsg = '';
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  /** control for the selected store */
  public storeCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public storeFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of stores filtered by search keyword */
  public filteredStores: any = [];

  protected filterStores() {
    if (!this.stores) {
      return;
    }
    // get the search keyword
    let search = this.storeFilterCtrl.value;
    if (!search) {
      this.filteredStores = this.stores;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the stores
    this.filteredStores = this.stores.filter(
      (store: any) => store.store_code.toLowerCase().indexOf(search) > -1
    );
  }
  onlyChar(ev: any) {
    let onlynum = /^[a-zA-Z ]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
}
