<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-campaign" role="tabpanel"
            aria-labelledby="v-pills-campaign-tab">
            <div class="store-details-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1">
                  <button class="btn back pl20" (click)="goToStore()">Back</button>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                  <button class="btn-primary-sm btn-grey shine edit" (click)="editStore()">
                    Edit
                  </button>
                </div>
              </div>

              <div class="store-details mt20">
                <h1>Store Detail</h1>
                <div class="filelds-table mt10">
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Store Code</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{store.store_code }}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Store Name</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ store.store_name }} </label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Zone</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ store.zone }}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>City</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ store.city}}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Zipcode</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ store.zipCode}}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>State</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ store.state }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>