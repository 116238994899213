import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  constructor(
    private router: Router, private apiService: LoginService
  ) { }
  token = localStorage.getItem('token');
  activatePriority: boolean = false; // hidden by default
  activateCampaign: boolean = false; // hidden by default
  activateStaff: boolean = false; // hidden by default
  activateStore: boolean = false; // hidden by default
  ngOnInit(): void {
    
    if(this.router.url.includes('campaign')){
      this.activateCampaign = true;
    }
    else if (this.router.url.includes('staff')){
      this.activateStaff = true;
    }
    else if (this.router.url.includes('store')){
      this.activateStore = true;
    }
    else if (this.router.url.includes('priority')){
      this.activatePriority = true;
    }
    else{
      console.warn("default");
    }
  }


 logOut() {
  
    this.apiService.logout().subscribe((res: any) => {
      console.log(res);
      this.router.navigateByUrl('/login');
    });
    localStorage.clear();
    
  }

  isShown: boolean = false; // hidden by default

  toggleShow() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isShown = !this.isShown;
    } else {
      console.log('not mobile');
    }
  }

  

}
