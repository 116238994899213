import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-priority-list',
  templateUrl: './priority-list.component.html',
  styleUrls: ['./priority-list.component.scss']
})
export class PriorityListComponent implements OnInit {

  constructor(
    private service: LoginService,
    private router: Router
  ) { }

  priorities: any = [];
  token: any;

  ngOnInit(): void {
    this.token = this.service.checkToken();
    if(this.token){
    this.getPriorities();
    }
  }

  getPriorities(){
    this.service.getPriorities().subscribe((res: any) => {
      this.priorities = res.result;
    })
  }

  addPriority(){
     this.router.navigate(['/add-priority']);
  }

  editPriority(data: any){
    this.router.navigate(['/edit-priority', {id: data.id, name: data.priority_name}]);
  }

}
