<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="campain-container">
              <div class="header-div row m-0">
                <div class="col-lg-6 col-sm-12 col-md-12 p-0">
                  <h1 class="component-heading">Campaign</h1>
                  <p class="component-para">
                    Lorem ipsum dolor amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div
                  class="col-lg-6 col-sm-12 col-md-12 p-0 d-flex justify-content-end action-btns"
                >
                  <div class="search-bar mr20">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-serach"
                      [(ngModel)]="searchKey"
                      (keyup)="searchData(true)"
                    />
                    <button
                      class="search-btn"
                      type="button"
                      (click)="searchData()"
                    >
                      Serach
                    </button>
                  </div>

                  <button
                    class="btn-blue btn-primary-sm shine"
                    (click)="addCampaign()"
                  >
                    Add Campaign
                  </button>
                </div>
              </div>

              <div class="table-container pt10">
                <div class="custom-loader" *ngIf="!campaigns">
                  <div class="text-center loader-wrapper">
                    <div class="spinner-border text-primary-new" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>

                <div class="table-responsive border-radius table-fixed-height">
                  <table class="table table-borderless b-radius">
                    <thead>
                      <tr class="heading">
                        <th scope="col">ID</th>
                        <th scope="col">Image</th>
                        <th scope="col">Campaign Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">
                          <span class="status pr10" (click)="showDropDown()"
                            >Status
                            <img
                              class="pl10"
                              src="../../assets/images/filter-icon.png"
                              alt="filter-icon"
                            />
                          </span>
                          <span>
                            <img
                              class="download-file pl10"
                              src="../../assets/images/download-icon.png"
                              (click)="download()"
                              alt="download-icon"
                          /></span>
                          <form [formGroup]="filterForm">
                            <div
                              class="myaccount-dropdown"
                              [class.open]="enableUserDropdown"
                            >
                              <h3>Status</h3>
                              <div class="d-flex flex-wrap mb-2 pt-1">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    formControlName="active"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Active
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    formControlName="inactive"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Inactive
                                  </label>
                                </div>
                              </div>

                              <h3>Campaign Creation</h3>
                              <div
                                class="d-flex flex-wrap justify-content-between mt10 customForm"
                              >
                                <div class="form-group form-item">
                                  <label for="customField4">From</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    id="customField4"
                                    placeholder="Select Date"
                                    value="Select Date"
                                    formControlName="startDate"
                                  />
                                </div>
                                <div
                                  *ngIf="
                                    submittedCampaignForm &&
                                    campaignFormVal.startDate.errors
                                  "
                                  class="error"
                                >
                                  <span
                                    class="mb10 d-block"
                                    *ngIf="
                                      campaignFormVal.startDate.errors.required
                                    "
                                    style="color: red; margin-left: 2px"
                                    >Start date is required</span
                                  >
                                </div>
                                <div class="form-group form-item">
                                  <label for="customField4">To</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    id="customField4"
                                    placeholder="Select Date"
                                    value="Select Date"
                                    formControlName="endDate"
                                    (ngModelChange)="onEndDateChanged($event)"
                                  />
                                </div>
                                <div
                                  *ngIf="
                                    submittedCampaignForm &&
                                    campaignFormVal.endDate.errors
                                  "
                                  class="error"
                                >
                                  <span
                                    class="mb10 d-block"
                                    *ngIf="
                                      campaignFormVal.endDate.errors.required
                                    "
                                    style="color: red; margin-left: 2px"
                                    >End date is required</span
                                  >
                                </div>
                              </div>

                              >
                              <div class="flex-grow-1 d-flex">
                                <button
                                  class="btn-primary-sm btn-grey shine cancel"
                                  (click)="hideDropDown()"
                                >
                                  Cancel
                                </button>
                                <button
                                  class="btn-primary-sm btn-blue ml20 shine save"
                                  type="submit"
                                  (click)="saveFilter(true)"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      *ngFor="
                        let campaign of campaigns
                          | paginate
                            : {
                                itemsPerPage: 6,
                                currentPage: page,
                                totalItems: total
                              }
                      "
                    >
                      <tr>
                        <td>
                          <span class="campaign-name">{{ campaign.id }}</span>
                        </td>
                        <td>
                          <img
                            class="border-radius"
                            [src]="
                              campaign.campaign_image
                                ? campaign.campaign_image
                                : '../../../assets/images/upload-campaign-sample-img.png'
                            "
                            height="30px"
                            width="50px"
                            alt="upload-campaign"
                          />
                        </td>
                        <td>
                          <span class="campaign-name">{{
                            campaign.campaign_name
                          }}</span>
                        </td>
                        <td>
                          <span class="text-truncate description"
                            >{{
                              campaign.campaign_message | slice : 0 : 30
                            }}....</span
                          >
                        </td>
                        <td (click)="getCampaignId(campaign.id)">
                          <span class="status-result hyperLink">
                            {{
                              campaign.is_active ? "Active" : "Inactive"
                            }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button class="btn-template">
                      <a [href]="url">Uploading Template</a>
                    </button>
                  </div>
                  <div class="col-lg-6">
                    <pagination-controls
                      class="custom-pagination"
                      (pageChange)="pageChangeEvent($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="campaigns?.length < 1" class="text-center text-red p-3">
              No Data Found
              <!-- <button (click)="OkButton()">Ok</button> -->
              <!--above button will be required in future-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
