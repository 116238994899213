import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.scss'],
})
export class EditCampaignComponent implements OnInit {
  campaignId: any;
  style1: boolean = false;
  token: any;
  campaignForm: any;
  campaignDetails: any;
  campaignPriorities: any;
  campName = 'my camp';
  selectedPriorities: any = [];
  existingPriorities: any = []
  formData: FormData = new FormData();
  image: any;
  selectPriorityIds: any = [];
  isImageChange: boolean = false;
  custom_fields: any = [];
  constructor(
    private route: ActivatedRoute,
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.campaignId = this.route.snapshot.params['data'];
  }

  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getCampaign();
    }
    this.campaignForm = this.formBuilder.group({
      campaign_name: [this.campName],
      is_active: [],
      campaign_message: [],
      custom_field_1: [null],
      custom_field_1_visible: [false],
      custom_field_1_type:['string'],
      custom_field_2: [null],
      custom_field_2_visible: [false],
      custom_field_2_type:['string'],
      custom_field_3: [null],
      custom_field_3_visible: [false],
      custom_field_3_type:['string'],
      custom_field_4: [null],
      custom_field_4_visible: [false],
      custom_field_4_type:['string'],
      custom_field_5: [null],
      custom_field_5_visible: [false],
      custom_field_5_type:['string'],
      custom_field_6: [null],
      custom_field_6_visible: [false],
      custom_field_6_type:['string'],
      custom_field_7: [null],
      custom_field_7_visible: [false],
      custom_field_7_type:['string'],
      custom_field_8: [null],
      custom_field_8_visible: [false],
      custom_field_8_type:['string'],
      custom_field_9: [null],
      custom_field_9_visible: [false],
      custom_field_9_type:['string'],
      custom_field_10: [null],
      custom_field_10_visible: [false],
      custom_field_10_type:['string'],
    });
  }

  removeDuplicateValues(mergedArray: any) {

    let uniqueArray = [];
    let seen = new Set();

    for (let each of mergedArray) {
      if (!seen.has(each.priority_name)) {
        seen.add(each.priority_name);
        uniqueArray.push({ ...each });
      }
    }
    return uniqueArray;
  };

  getCampaign() {
    this.apiService.getCampaign(this.campaignId).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.campaignDetails = res['result'];
          this.image =
            this.campaignDetails.capData['campaign_image'] ||
            `../../../assets/images/upload-campaign-sample-img.png`;
          // Set Values
          this.campaignForm.controls['campaign_name'].setValue(
            this.campaignDetails.capData['campaign_name']
          );
          this.campaignForm.controls['campaign_message'].setValue(
            this.campaignDetails.capData['campaign_message']
          );
          this.campaignForm.controls['is_active'].setValue(
            this.campaignDetails.capData['is_active']
          );
          if (this.campaignDetails.capData.tbl_campaign_customfields && this.campaignDetails.capData.tbl_campaign_customfields.length > 0) {
            this.campaignForm.controls['custom_field_1'].setValue(
              this.campaignDetails.capData.tbl_campaign_customfields[0][
              'field_name'
              ]
            );
            this.campaignForm.controls['custom_field_1_visible'].setValue(
              this.campaignDetails.capData.tbl_campaign_customfields[0][
              'visibility'
              ]
            );
            this.campaignForm.controls['custom_field_1_type'].setValue(
              this.campaignDetails.capData.tbl_campaign_customfields[0][
              'field_type'
              ]
            );
            this.campaignForm.controls['custom_field_1'].disable();
            this.campaignForm.controls['custom_field_1_type'].disable();
          }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 1 && this.campaignDetails.capData.tbl_campaign_customfields[1]) {
              this.campaignForm.controls['custom_field_2'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[1][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_2_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[1][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_2_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[1][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_2'].disable();
              this.campaignForm.controls['custom_field_2_type'].disable();
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 2 && this.campaignDetails.capData.tbl_campaign_customfields[2]) {
              this.campaignForm.controls['custom_field_3'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[2][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_3_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[2][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_3_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[2][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_3'].disable();
              this.campaignForm.controls['custom_field_3_type'].disable();
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 3 && this.campaignDetails.capData.tbl_campaign_customfields[3]) {
              this.campaignForm.controls['custom_field_4'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[3][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_4_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[3][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_4_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[3][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_4'].disable();
              this.campaignForm.controls['custom_field_4_type'].disable()
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 4 && this.campaignDetails.capData.tbl_campaign_customfields[4]) {
              this.campaignForm.controls['custom_field_5'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[4][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_5_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[4][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_5_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[4][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_5'].disable();
              this.campaignForm.controls['custom_field_5_type'].disable();
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 5 && this.campaignDetails.capData.tbl_campaign_customfields[5]) {
              this.campaignForm.controls['custom_field_6'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[5][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_6_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[5][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_6_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[5][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_6'].disable();
              this.campaignForm.controls['custom_field_6_type'].disable();
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 6 && this.campaignDetails.capData.tbl_campaign_customfields[6]) {
              this.campaignForm.controls['custom_field_7'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[6][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_7_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[6][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_7_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[6][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_7'].disable();
              this.campaignForm.controls['custom_field_7_type'].disable()
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 7 && this.campaignDetails.capData.tbl_campaign_customfields[7]) {
              this.campaignForm.controls['custom_field_8'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[7][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_8_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[7][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_8_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[7][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_8'].disable();
              this.campaignForm.controls['custom_field_8_type'].disable();
            }



            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 8 && this.campaignDetails.capData.tbl_campaign_customfields[8]) {
              this.campaignForm.controls['custom_field_9'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[8][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_9_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[8][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_9_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[8][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_9'].disable();
              this.campaignForm.controls['custom_field_9_type'].disable();
            }

            if (this.campaignDetails.capData.tbl_campaign_customfields.length > 9 && this.campaignDetails.capData.tbl_campaign_customfields[9]) {
              this.campaignForm.controls['custom_field_10'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[9][
                'field_name'
                ]
              );
              this.campaignForm.controls['custom_field_10_visible'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[9][
                'visibility'
                ]
              );
              this.campaignForm.controls['custom_field_10_type'].setValue(
                this.campaignDetails.capData.tbl_campaign_customfields[9][
                'field_type'
                ]
              );
              this.campaignForm.controls['custom_field_10'].disable();
              this.campaignForm.controls['custom_field_10_type'].disable()
            }

          }
      },
      (err) => {
        this.toaster.error(`Error WHile Fetching Campaign Details` + err);
      }
    );
    this.apiService.getAllPriority(this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.campaignPriorities = res['result'];
          this.apiService.getCampaignPriority(this.campaignId).subscribe((res: any) => {
            if (res['status'] == 'success') {
              this.existingPriorities = res['result']
              this.campaignPriorities = [...this.campaignPriorities, ...this.existingPriorities]
              this.campaignPriorities = this.removeDuplicateValues(this.campaignPriorities)
              console.log("this.campaignPriorities ", this.campaignPriorities)
              this.campaignPriorities.forEach((item: any) => {
                if (this.existingPriorities.filter((eachRecord: { priority_name: any; }) => eachRecord.priority_name === item.priority_name).length > 0) {
                  item['color'] = true;
                } else {
                  item['color'] = false;
                }

              });
            }
          },
            (err) => {
              this.toaster.error(
                `Error While Fetching Campaign Priority Details` + err
              );
            })

        }
      },
      (err) => {
        this.toaster.error(
          `Error While Fetching Campaign Priority Details` + err
        );
      }
    );

  }
  handleFileInput(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.image = event.target.files[0].name;
      this.formData.append('file', file);
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.image = reader.result;
      };
      this.isImageChange = true;
    }
  }
  onSubmitCampaignForm() {
    if (this.campaignForm.invalid) {
      return;
    } else {
      const result = ['custom_field_1', 'custom_field_2', 'custom_field_3', 'custom_field_4', 'custom_field_5', 'custom_field_6', 'custom_field_7', 'custom_field_8', 'custom_field_9', 'custom_field_10']

      for (const allCustom of result) {
        let eachCustomField = this.campaignForm.controls[allCustom].value;

        // if (eachCustomField?.toLowerCase() === 'dob') {
        //   this.custom_fields.push({
        //     field_name: eachCustomField,
        //     field_type: 'date',
        //     visibility: this.campaignForm.value[`${allCustom}_visible`],
        //   });
        // } else if (eachCustomField?.toLowerCase() === 'pincode') {
        //   this.custom_fields.push({
        //     field_name: eachCustomField,
        //     field_type: 'number',
        //     visibility: this.campaignForm.value[`${allCustom}_visible`],
        //   });
        // } else {
          if(eachCustomField){
            this.custom_fields.push({
              field_name: eachCustomField,
              field_type: this.campaignForm.controls[`${allCustom}_type`].value,
              visibility: this.campaignForm.value[`${allCustom}_visible`],
            });
          }
          
       // }
      }
      this.custom_fields = this.custom_fields.filter((e: any) => e.field_name);
      this.campaignForm.value.custom_fields = this.custom_fields;
      this.apiService
        .editCampaign(this.token, this.campaignForm.value, this.campaignId)
        .subscribe(
          (res: any) => {
            if (this.isImageChange) {
              this.apiService
                .addCampaignImage(this.token, this.formData, this.campaignId)
                .subscribe(
                  (res: any) => {
                    if (res['status'] === 'success') {
                      this.toaster.success(
                        `Campaign Got Updated with image id ${res['result']}`
                      );
                    }
                  },
                  (err: any) => {
                    if (err['status'] == 400) {
                      this.toaster.error(err.error.message);
                    }
                  }
                );
            }
            if (this.selectPriorityIds) {
              this.apiService
                .addCampaignPriorities(
                  this.token,
                  this.selectPriorityIds,
                  this.campaignId
                )
                .subscribe(
                  (res: any) => {
                    if (res['status'] === 'success') {
                      this.toaster.success(
                        `Campaign Got Updated with priorities`
                      );
                    }
                  },
                  (err: any) => {
                    if (err['status'] == 400) {
                      this.toaster.error(err.error.message);
                    }
                  }
                );
            }
            if (res['status'] === 'success') {
              this.toaster.success(`Campaign Got updated...`);
              let data = {
                data: this.campaignId,
              };
              this.router.navigate(['/campaign-detail', data]);
            }
          },
          (err: any) => {
            if (err['status'] == 400) {
              this.toaster.error(err.error.message);
            }
          }
        );
    }
  }
  prioritySelect(priority: any) {
    priority.color = true;
    this.selectedPriorities.push(priority.priority_name);
    this.selectPriorityIds.push(`${priority.id}`);
  }

  goToBack() {
    let data = {
      data: this.campaignId,
    };
    this.router.navigate(['/campaign-detail', data]);
  }
  onlyChar(ev: any) {
    let onlynum = /^[a-zA-Z]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyAlphaNumeric(event: any) {
    let onlynum = /^[A-Za-z0-9\s]*$/;
    if (!onlynum.test(event.key)) {
      event.preventDefault();
    }
  }
}
