import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { stateCityNames } from '../../stateCity';


@Component({
  selector: 'app-edit-store',
  templateUrl: './edit-store.component.html',
  styleUrls: ['./edit-store.component.scss'],
})
export class EditStoreComponent implements OnInit {
  storeId: any;
  store: any;
  storeForm: any;
  stores: any;
  storeIds: any;
  token: any;
  allStatesCity:any = stateCityNames;
  allStates = Object.keys(stateCityNames).map(each => ({name:each,selected:false}))
  allZone = [
    { name: 'EAST', selected: false },
    { name: 'WEST', selected: false },
    { name: 'NORTH', selected: false },
    { name: 'SOUTH', selected: false },
  ];
  constructor(
    private route: ActivatedRoute,
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.storeId = this.route.snapshot.params['data'];
  }

  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getStore();
    }
    this.storeForm = this.formBuilder.group({
      zone: ['', Validators.required],
      store_code: ['', Validators.required],
      store_name: ['', [Validators.required,Validators.pattern(/^[a-zA-Z](?:[a-zA-Z_\- ]*[a-zA-Z])?$/)]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(6)]],
    });
  }
  isShown: boolean = false; // hidden by default
  getStore() {
    this.apiService.getStore(this.storeId, this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.store = res['result'];
          this.storeForm.controls['zone'].setValue(this.store['zone']);
          this.storeForm.controls['store_code'].setValue(
            this.store['store_code']
          );
          this.storeForm.controls['store_name'].setValue(
            this.store['store_name']
          );
          
          this.storeForm.controls['state'].setValue(this.store['state']);
          this.storeForm.controls['city'].setValue(this.store['city']);
          this.storeForm.controls['zipCode'].setValue(this.store['zipCode']);
          const selectedStateElement = this.allStates.filter(
            (e: any) =>
              e.name.toUpperCase() ===
              this.storeForm.controls['state'].value.toUpperCase()
          );
          selectedStateElement[0]
            ? (selectedStateElement[0].selected = true)
            : null;
          const selectedElement = this.allZone.filter(
            (e: any) =>
              e.name.toUpperCase() ===
              this.storeForm.controls['zone'].value.toUpperCase()
          );
          selectedElement[0] ? (selectedElement[0].selected = true) : null;
        }
      },
      (err) => {
        this.toaster.error(err.message);
      }
    );
  }
  changeZone(e: any) {
    this.storeForm.controls['zone'].setValue(e.target.value);
  }
  changeState(e: any) {
    this.storeForm.controls['state'].setValue(e.target.value);
  }
  onSubmit() {
    this.apiService
      .editStore(this.storeId, this.storeForm.value, this.token)
      .subscribe(
        (res: any) => {
          if (res['status'] === 'success') {
            this.toaster.success(`${res['result']}`);
            this.goToStore();
          }else{
            this.toaster.error(`${res.error?.['message']}`);
          }
        },
        (err) => {
          this.toaster.error(`${err.error.message}`);
        }
      );
  }

  goToStore() {
    let data = {
      data: this.storeId,
    };
    this.router.navigate(['/store', data]);
  }
  onlyNumbers(ev: any) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyChar(ev: any) {
    let onlynum = /^[a-zA-Z_\- ]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyAlphaNumeric(event: any) {
    let onlynum = /^[A-Za-z0-9]*$/;
    if (!onlynum.test(event.key)) {
      event.preventDefault();
    }
  }
}
