<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="staff-list-container">
              <div class="header-div row m-0">
                <div class="col-lg-6 col-sm-12 p-0">
                  <h1 class="component-heading">Staff Information</h1>
                  <p class="component-para">
                    Lorem ipsum dolor amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div class="col-lg-6 col-sm-12 p-0 justify-content-end action-btns">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12 p-0">
                      <div class="search-bar mr20">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="basic-serach"
                          [(ngModel)]="searchKey"
                          (keyup)="searchData(true)"
                        />
                        <button
                          class="search-btn"
                          type="button"
                          (click)="searchData(true)"
                        >
                          Serach
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 p-0">
                      <button
                      class="btn-blue btn-primary-sm shine"
                      (click)="goToAdd()"
                      
                    >
                    Create ID
                    </button>
                    </div>
                    <div class="col-lg-2 col-sm-12 p-0">
                      <div>
                        <input
                        type="file"
                        #file
                        hidden
                        (change)="handleFileInput($event)"
                      />
                      <button
                      (click)="file.click()"
                      class="btn-primary-sm btn-blue ml20 shine"
                    >
                    Import Ids
                    </button>
                      </div>
                    </div>

                  </div>
                  
                 
                 
              
                
                </div>
              </div>
              <div class="table-container pt10">
                <div class="custom-loader" *ngIf="!staffList">
                  <div class="text-center loader-wrapper">
                    <div class="spinner-border text-primary-new" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>
                <div *ngIf="staffList?.length < 1">No Data Found</div>
                <div class="table-responsive border-radius">
                  <table class="table table-borderless b-radius">
                    <thead>
                      <tr class="heading">
                        <th class="col-2" scope="col">First Name</th>
                        <th class="col-2" scope="col">Last Name</th>
                        <th class="col-2" scope="col">Mobile No.</th>
                        <th class="col-2" scope="col">Store Code</th>
                        <th class="col-3 offset-md-1" scope="col">
                          Last login time
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      *ngFor="
                        let staff of staffList
                          | paginate
                            : {
                                itemsPerPage: 6,
                                currentPage: page,
                                totalItems: total
                              }
                      "
                    >
                      <tr>
                        <td class="col-2">
                          <span class="first-name">{{ staff.first_name }}</span>
                        </td>

                        <td class="col-2">
                          <span class="last-name">{{ staff.last_name }}</span>
                        </td>
                        <td class="col-2" (click)="getStaffId(staff.id)">
                          <span class="mobile-number hyperLink">{{
                            staff.mobile
                          }}</span>
                        </td>

                        <td class="col-2">
                          <span class="store-code">{{ staff.store_code }}</span>
                        </td>
                        <td class="col-3 offset-md-1">
                          <span class="last-log-time">{{
                            staff.lastLoginTime
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button class="btn-template">
                      <a [href]="url">Uploading Template</a>
                    </button>
                  </div>
                  <div class="col-lg-6">
                    <pagination-controls
                      class="custom-pagination"
                      (pageChange)="pageChangeEvent($event)"
                    ></pagination-controls>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="progress-loader" *ngIf="!isUploading">
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        style="width: 100%"
        aria-valuenow="60"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <!-- 60% -->
      </div>
    </div>
  </div>
</section>
