import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.scss'],
})
export class StaffDetailsComponent implements OnInit {
  staffId: any;
  token: any
  staff: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: LoginService,
    private toaster: ToastrService
  ) {
    this.staffId = this.route.snapshot.params['data'];
  }
  isShown: boolean = false; // hidden by default
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if(this.token){
    this.getStaff();
    }
  }
  getStaff() {
    this.apiService.getStaff(this.staffId, this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.staff = res['result'];
        }
      },
      (err) => {
        this.toaster.error(err.message);
      }
    );
  }
  displayStyle = 'none';
  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }
  goToStaff() {
    this.router.navigate(['/staff-list']);
  }
  deleteStaff() {
    this.apiService.deleteStaff(this.staffId, this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.toaster.success(`Staff Deleted ` + res['result']);
          this.router.navigate(['/staff-list']);
        }
      },
      (err) => {
        this.toaster.error(err.message);
      }
    );
  }
  editStaff(){
    let data = {
      data: this.staffId,
    };
    this.router.navigate(['/edit-staff', data])
  }
}
