<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="staff-details-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1">
                  <button class="btn back pl20" (click)="goToStaff()">
                    Back
                  </button>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                  <button
                    class="btn-primary-sm btn-grey shine edit"
                    (click)="editStaff()"
                  >
                    Edit
                  </button>
                  <button
                    class="btn-primary-sm btn-red shine delete ml20"
                    (click)="openPopup()"
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div class="staff-details mt20">
                <h1>ID Detail</h1>
                <div class="filelds-table mt10">
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>First Name</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ staff?.first_name }}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Last Name</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ staff?.last_name }}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Mobile Number</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>+91 {{ staff?.mobile }}</label>
                    </div>
                  </div>
                  <div class="pt20 item">
                    <div class="d-inline-block attribute-name">
                      <label>Store Code</label>
                    </div>
                    <div class="d-inline-block ml30 attribute-value">
                      <label>{{ staff?.store_code }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="modal custom-popup"
                tabindex="-1"
                role="dialog"
                [ngStyle]="{ display: displayStyle }"
              >
                <div class="modal-dialog mt50" role="document">
                  <div class="overlay"></div>
                  <div class="modal-content">
                    <span class="close-popup" (click)="closePopup()"
                      ><img
                        class="w-100"
                        src="../../../assets/images/close-icon-black.png"
                    /></span>
                    <img
                      class="mt20 delete-icon"
                      src="../../../assets/images/delete-empty.png"
                    />
                    <div class="modal-header justify-content-center mt20">
                      <h4 class="modal-title">Want to Delete?</h4>
                    </div>
                    <div class="modal-body mt20">
                      <p class="text-center">
                        Are you sure you want to delete this staff info? You
                        will not be able to recover them.
                      </p>
                    </div>
                    <div class="modal-footer justify-content-center mt20">
                      <button
                        type="button"
                        class="btn-primary-sm btn-red shine delete"
                        (click)="deleteStaff()"
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        class="btn-primary-sm btn-no-background"
                        (click)="closePopup()"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
