



export const stateCityNames = {

    "ANDAMAN & NICOBAR ISLANDS":[{
            "id": "1",
            "city": "South Andaman"
        },
        {
            "id": "2",
            "city": "North And Middle Andaman"
        },
        {
            "id": "3",
            "city": "Nicobar"
        }],
    "ANDHRA PRADESH":[ {
            "id": "1",
            "city": "Ananthapur"
        },
        {
            "id": "2",
            "city": "Cuddapah"
        },
        {
            "id": 3,
            "city": "Chittoor"
        },
        {
            "id": 4,
            "city": "Kurnool"
        },
        {
            "id": 5,
            "city": "Prakasam"
        },
        {
            "id": 6,
            "city": "West Godavari"
        },
        {
            "id": 7,
            "city": "Krishna"
        },
        {
            "id": 8,
            "city": "Nellore"
        },
        {
            "id": 9,
            "city": "Guntur"
        },
        {
            "id": 10,
            "city": "East Godavari"
        },
        {
            "id": 11,
            "city": "Visakhapatnam"
        },
        {
            "id": 12,
            "city": "Vizianagaram"
        },
        {
            "id": 13,
            "city": "Srikakulam"
        }],
    "ARUNACHAL PRADESH":[{
            "id": "1",
            "city": "Lower Dibang Valley"
        },
        {
            "id": "2",
            "city": "East Siang"
        },
        {
            "id": 3,
            "city": "Dibang Valley"
        },
        {
            "id": 4,
            "city": "West Siang"
        },
        {
            "id": 5,
            "city": "Lohit"
        },
        {
            "id": 6,
            "city": "Papum Pare"
        },
        {
            "id": 7,
            "city": "Tawang"
        },
        {
            "id": 8,
            "city": "West Kameng"
        },
        {
            "id": 9,
            "city": "East Kameng"
        },
        {
            "id": 10,
            "city": "Lower Subansiri"
        },
        {
            "id": 11,
            "city": "Changlang"
        },
        {
            "id": 12,
            "city": "Tirap"
        },
        {
            "id": 13,
            "city": "Kurung Kumey"
        },
        {
            "id": "14",
            "city": "Upper Siang"
        },
        {
            "id": "15",
            "city": "Upper Subansiri"
        }],
    "ASSAM":[{
            "id": "1",
            "city": "Lakhimpur"
        },
        {
            "id": "2",
            "city": "Dibrugarh"
        },
        {
            "id": 3,
            "city": "Dhemaji"
        },
        {
            "id": 4,
            "city": "Marigaon"
        },
        {
            "id": 5,
            "city": "Nagaon"
        },
        {
            "id": 6,
            "city": "Karbi Anglong"
        },
        {
            "id": 7,
            "city": "Golaghat"
        },
        {
            "id": 8,
            "city": "Sibsagar"
        },
        {
            "id": 9,
            "city": "Jorhat"
        },
        {
            "id": 10,
            "city": "Tinsukia"
        },
        {
            "id": 11,
            "city": "Karimganj"
        },
        {
            "id": 12,
            "city": "Hailakandi"
        },
        {
            "id": 13,
            "city": "Cachar"
        },
        {
            "id": "14",
            "city": "North Cachar Hills"
        },
        {
            "id": "15",
            "city": "Darrang"
        },
        {
            "id": 16,
            "city": "Sonitpur"
        },
        {
            "id": 17,
            "city": "Bongaigaon"
        },
        {
            "id": 18,
            "city": "Kokrajhar"
        },
        {
            "id": 19,
            "city": "Goalpara"
        }],
    "BIHAR":[ {
            "id": "1",
            "city": "Begusarai"
        },
        {
            "id": "2",
            "city": "Khagaria"
        },
        {
            "id": 3,
            "city": "Darbhanga"
        },
        {
            "id": 4,
            "city": "Madhubani"
        },
        {
            "id": 5,
            "city": "Muzaffarpur"
        },
        {
            "id": 6,
            "city": "Samastipur"
        },
        {
            "id": 7,
            "city": "Sitamarhi"
        },
        {
            "id": 8,
            "city": "East Champaran"
        },
        {
            "id": 9,
            "city": "Supaul"
        },
        {
            "id": 10,
            "city": "Vaishali"
        },
        {
            "id": 11,
            "city": "Purnia"
        },
        {
            "id": 12,
            "city": "Araria"
        },
        {
            "id": 13,
            "city": "Katihar"
        },
        {
            "id": "14",
            "city": "Kishanganj"
        },
        {
            "id": "15",
            "city": "Madhepura"
        },
        {
            "id": 16,
            "city": "Saharsa"
        },
        {
            "id": 17,
            "city": "Saran"
        },
        {
            "id": 18,
            "city": "Siwan"
        },
        {
            "id": 19,
            "city": "Bhojpur"
        },{
            "id": "20",
            "city": "Sheohar"
        },
        {
            "id": "21",
            "city": "Gopalganj"
        },
        {
            "id": 22,
            "city": "West Champaran"
        },
        {
            "id": 23,
            "city": "Gaya"
        },
        {
            "id": 24,
            "city": "Aurangabad(BH)"
        },
        {
            "id": 25,
            "city": "Arwal"
        },
        {
            "id": 26,
            "city": "Bhagalpur"
        },
        {
            "id": 27,
            "city": "Banka"
        },
        {
            "id": 28,
            "city": "Munger"
        },
        {
            "id": 29,
            "city": "Buxar"
        },
        {
            "id": 30,
            "city": "Jehanabad"
        },
        {
            "id": 31,
            "city": "Patna"
        },
        {
            "id": 32,
            "city": "Sheikhpura"
        },
        {
            "id": 33,
            "city": "Jamui"
        },
        {
            "id": 34,
            "city": "Lakhisarai"
        },
        {
            "id": 35,
            "city": "Nalanda"
        },
        {
            "id": 36,
            "city": "Nawada"
        },
        {
            "id": 37,
            "city": "Rohtas"
        },
        {
            "id": 38,
            "city": "Kaimur (Bhabua)"
        }],
    "CHATTISGARH":[{
            "id": "1",
            "city": "Chandigarh"
        },
        {
            "id": "2",
            "city": "Kanker"
        },
        {
            "id": 3,
            "city": "Bastar"
        },
        {
            "id": 4,
            "city": "Dantewada"
        },
        {
            "id": 5,
            "city": "Bijapur(CGH)"
        },
        {
            "id": 6,
            "city": "Narayanpur"
        },
        {
            "id": 7,
            "city": "Bilaspur(CGH)"
        },
        {
            "id": 8,
            "city": "Janjgir-champa"
        },
        {
            "id": 9,
            "city": "Korba"
        },
        {
            "id": 10,
            "city": "Durg"
        },
        {
            "id": 11,
            "city": "Rajnandgaon"
        },
        {
            "id": 12,
            "city": "Kawardha"
        },
        {
            "id": 13,
            "city": "Surguja"
        },
        {
            "id": 14,
            "city": "Raigarh"
        },
        {
            "id": 15,
            "city": "Jashpur"
        },
        {
            "id": 16,
            "city": "Koriya"
        },
        {
            "id": 17,
            "city": "Raipur"
        },
        {
            "id": 18,
            "city": "Mahasamund"
        },
        {
            "id": 19,
            "city": "Dhamtari"
        },{"id":20,"city":"Gariaband"}],
    "DADRA & NAGAR HAVELI":[{"id":1,"city":"Dadra & Nagar Haveli"}],
    "DAMAN & DIU":[{"id":1,"city":"Diu"},{"id":2,"city":"Daman"}],
    "DELHI":[ {
            "id": "1",
            "city": "East Delhi"
        },
        {
            "id": "2",
            "city": "North East Delhi"
        },
        {
            "id": 3,
            "city": "North West Delhi"
        },
        {
            "id": 4,
            "city": "North Delhi"
        },
        {
            "id": 5,
            "city": "Central Delhi"
        },
        {
            "id": 6,
            "city": "New Delhi"
        },
        {
            "id": 7,
            "city": "South Delhi"
        },
        {
            "id": 8,
            "city": "South West Delhi"
        },
        {
            "id": 9,
            "city": "West Delhi"
        }],
    "GOA":[ {
            "id": 1,
            "city": "South Goa"
        }, {
            "id": 2,
            "city": "North Goa"
        }],
    "GUJARAT":[{
            "id": "1",
            "city": "Ahmedabad"
        },
        {
            "id": "2",
            "city": "Gandhi Nagar"
        },
        {
            "id": 3,
            "city": "Banaskantha"
        },
        {
            "id": 4,
            "city": "Mahesana"
        },
        {
            "id": 5,
            "city": "Surendra Nagar"
        },
        {
            "id": 6,
            "city": "Patan"
        },
        {
            "id": 7,
            "city": "Sabarkantha"
        },
        {
            "id": 8,
            "city": "Amreli"
        },
        {
            "id": 9,
            "city": "Rajkot"
        },
        {
            "id": 10,
            "city": "Junagadh"
        },
        {
            "id": 11,
            "city": "Bhavnagar"
        },
        {
            "id": 12,
            "city": "Jamnagar"
        },
        {
            "id": 13,
            "city": "Porbandar"
        },
        {
            "id": 14,
            "city": "Kachchh"
        },
        {
            "id": 15,
            "city": "Anand"
        },
        {
            "id": 16,
            "city": "Kheda"
        },
        {
            "id": 17,
            "city": "Surat"
        },
        {
            "id": 18,
            "city": "The Dangs"
        },
        {
            "id": 19,
            "city": "Tapi"
        },{
            "id": "20",
            "city": "Navsari"
        },
        {
            "id": "21",
            "city": "Vadodara"
        },
        {
            "id": 22,
            "city": "Bharuch"
        },
        {
            "id": 23,
            "city": "Narmada"
        },
        {
            "id": 24,
            "city": "Dahod"
        },
        {
            "id": 25,
            "city": "Panch Mahals"
        },
        {
            "id": 26,
            "city": "Valsad"
        }],
    "HARYANA":[{
            "id": "1",
            "city": "Ambala"
        },
        {
            "id": "2",
            "city": "Yamuna Nagar"
        },
        {
            "id": 3,
            "city": "Panchkula"
        },
        {
            "id": 4,
            "city": "Bhiwani"
        },
        {
            "id": 5,
            "city": "Faridabad"
        },
        {
            "id": 6,
            "city": "Gurgaon"
        },
        {
            "id": 7,
            "city": "Rewari"
        },
        {
            "id": 8,
            "city": "Mahendragarh"
        },
        {
            "id": 9,
            "city": "Hisar"
        },
        {
            "id": 10,
            "city": "Sirsa"
        },
        {
            "id": 11,
            "city": "Fatehabad"
        },
        {
            "id": 12,
            "city": "Karnal"
        },
        {
            "id": 13,
            "city": "Panipat"
        },
        {
            "id": 14,
            "city": "Jind"
        },
        {
            "id": 15,
            "city": "Kaithal"
        },
        {
            "id": 16,
            "city": "Kurukshetra"
        },
        {
            "id": 17,
            "city": "Jhajjar"
        },
        {
            "id": 18,
            "city": "Rohtak"
        },
        {
            "id": 19,
            "city": "Sonipat"
        }],
    "HIMACHAL PRADESH":[{
            "id": "1",
            "city": "Chamba"
        },
        {
            "id": "2",
            "city": "Kangra"
        },
        {
            "id": 3,
            "city": "Bilaspur (HP)"
        },
        {
            "id": 4,
            "city": "Hamirpur(HP)"
        },
        {
            "id": 5,
            "city": "Una"
        },
        {
            "id": 6,
            "city": "Mandi"
        },
        {
            "id": 7,
            "city": "Kullu"
        },
        {
            "id": 8,
            "city": "Lahul & Spiti"
        },
        {
            "id": 9,
            "city": "Kinnaur"
        },
        {
            "id": 10,
            "city": "Shimla"
        },
        {
            "id": 11,
            "city": "Sirmaur"
        },
        {
            "id": 12,
            "city": "Solan"
        }],
    "JAMMU & KASHMIR":[{
            "id": "1",
            "city": "Bandipur"
        },
        {
            "id": "2",
            "city": "Baramulla"
        },
        {
            "id": 3,
            "city": "Kupwara"
        },
        {
            "id": 4,
            "city": "Jammu"
        },
        {
            "id": 5,
            "city": "Kathua"
        },
        {
            "id": 6,
            "city": "Udhampur"
        },
        {
            "id": 7,
            "city": "Poonch"
        },
        {
            "id": 8,
            "city": "Kargil"
        },
        {
            "id": 9,
            "city": "Leh"
        },
        {
            "id": 10,
            "city": "Rajauri"
        },
        {
            "id": 11,
            "city": "Reasi"
        },
        {
            "id": 12,
            "city": "Srinagar"
        },
        {
            "id": 13,
            "city": "Budgam"
        },
        {
            "id": 14,
            "city": "Ananthnag"
        },
        {
            "id": 15,
            "city": "Shopian"
        },
        {
            "id": 16,
            "city": "Pulwama"
        },
        {
            "id": 17,
            "city": "Kulgam"
        },
        {
            "id": 18,
            "city": "Doda"
        }],
    "JHARKHAND":[{
            "id": "1",
            "city": "Dhanbad"
        },
        {
            "id": "2",
            "city": "Bokaro"
        },
        {
            "id": 3,
            "city": "Giridh"
        },
        {
            "id": 4,
            "city": "Hazaribag"
        },
        {
            "id": 5,
            "city": "Chatra"
        },
        {
            "id": 6,
            "city": "Ramgarh"
        },
        {
            "id": 7,
            "city": "Koderma"
        },
        {
            "id": 8,
            "city": "Latehar"
        },
        {
            "id": 9,
            "city": "Garhwa"
        },
        {
            "id": 10,
            "city": "Palamau"
        },
        {
            "id": 11,
            "city": "Ranchi"
        },
        {
            "id": 12,
            "city": "Gumla"
        },
        {
            "id": 13,
            "city": "Simdega"
        },
        {
            "id": 14,
            "city": "Lohardaga"
        },
        {
            "id": 15,
            "city": "West Singhbhum"
        },
        {
            "id": 16,
            "city": "Khunti"
        },
        {
            "id": 17,
            "city": "Deoghar"
        },
        {
            "id": 18,
            "city": "Godda"
        },
        {
            "id": 19,
            "city": "Jamtara"
        }, {
            "id": "20",
            "city": "Sahibganj"
        },
        {
            "id": "21",
            "city": "Dumka"
        },
        {
            "id": 22,
            "city": "Pakur"
        },
        {
            "id": 23,
            "city": "Seraikela-kharsawan"
        },
        {
            "id": 24,
            "city": "East Singhbhum"
        }],
    "KARNATAKA":[ {
            "id": "1",
            "city": "Bangalore"
        },
        {
            "id": "2",
            "city": "Bangalore Rural"
        },
        {
            "id": 3,
            "city": "Ramanagar"
        },
        {
            "id": 4,
            "city": "Bagalkot"
        },
        {
            "id": 5,
            "city": "Bijapur(KAR)"
        },
        {
            "id": 6,
            "city": "Belgaum"
        },
        {
            "id": 7,
            "city": "Davangere"
        },
        {
            "id": 8,
            "city": "Bellary"
        },
        {
            "id": 9,
            "city": "Bidar"
        },
        {
            "id": 10,
            "city": "Dharwad"
        },
        {
            "id": 11,
            "city": "Gadag"
        },
        {
            "id": 12,
            "city": "Koppal"
        },
        {
            "id": 13,
            "city": "Yadgir"
        },
        {
            "id": 14,
            "city": "Gulbarga"
        },
        {
            "id": 15,
            "city": "Haveri"
        },
        {
            "id": 16,
            "city": "Uttara Kannada"
        },
        {
            "id": 17,
            "city": "Raichur"
        },
        {
            "id": 18,
            "city": "Chickmagalur"
        },
        {
            "id": 19,
            "city": "Chitradurga"
        },{
            "id": "20",
            "city": "Hassan"
        },
        {
            "id": "21",
            "city": "Kodagu"
        },
        {
            "id": 22,
            "city": "Chikkaballapur"
        },
        {
            "id": 23,
            "city": "Kolar"
        },
        {
            "id": 24,
            "city": "Mandya"
        },
        {
            "id": 25,
            "city": "Dakshina Kannada"
        },
        {
            "id": 26,
            "city": "Udupi"
        },
        {
            "id": 27,
            "city": "Mysore"
        },
        {
            "id": 28,
            "city": "Chamrajnagar"
        },
        {
            "id": 29,
            "city": "Shimoga"
        },
        {
            "id": 30,
            "city": "Tumkur"
        }],
    "KERALA":[{
            "id": "1",
            "city": "Wayanad"
        },
        {
            "id": "2",
            "city": "Kozhikode"
        },
        {
            "id": 3,
            "city": "Malappuram"
        },
        {
            "id": 4,
            "city": "Kannur"
        },
        {
            "id": 5,
            "city": "Kasargod"
        },
        {
            "id": 6,
            "city": "Palakkad"
        },
        {
            "id": 7,
            "city": "Alappuzha"
        },
        {
            "id": 8,
            "city": "Ernakulam"
        },
        {
            "id": 9,
            "city": "Kottayam"
        },
        {
            "id": 10,
            "city": "Pathanamthitta"
        },
        {
            "id": 11,
            "city": "Idukki"
        },
        {
            "id": 12,
            "city": "Thrissur"
        },
        {
            "id": 13,
            "city": "Kollam"
        },
        {
            "id": 14,
            "city": "Thiruvananthapuram"
        }],
    "LAKSHADWEEP":[{
            "id": 1,
            "city": "Lakshadweep"
        }],
    "MADHYA PRADESH":[{
            "id": "1",
            "city": "Seoni"
        },
        {
            "id": "2",
            "city": "Balaghat"
        },
        {
            "id": 3,
            "city": "Mandla"
        },
        {
            "id": 4,
            "city": "Dindori"
        },
        {
            "id": 5,
            "city": "Bhopal"
        },
        {
            "id": 6,
            "city": "Raisen"
        },
        {
            "id": 7,
            "city": "Chhatarpur"
        },
        {
            "id": 8,
            "city": "Tikamgarh"
        },
        {
            "id": 9,
            "city": "Panna"
        },
        {
            "id": 10,
            "city": "Betul"
        },
        {
            "id": 11,
            "city": "Chhindwara"
        },
        {
            "id": 12,
            "city": "Hoshangabad"
        },
        {
            "id": 13,
            "city": "Narsinghpur"
        },
        {
            "id": 14,
            "city": "Harda"
        },
        {
            "id": 15,
            "city": "Satna"
        },
        {
            "id": 16,
            "city": "Rewa"
        },
        {
            "id": 17,
            "city": "Damoh"
        },
        {
            "id": 18,
            "city": "Sagar"
        },
        {
            "id": 19,
            "city": "Anuppur"
        }, {
            "id": "20",
            "city": "Umaria"
        },
        {
            "id": "21",
            "city": "Shahdol"
        },
        {
            "id": 22,
            "city": "Sidhi"
        },
        {
            "id": 23,
            "city": "Singrauli"
        },
        {
            "id": 24,
            "city": "Vidisha"
        },
        {
            "id": 25,
            "city": "Ashok Nagar"
        },
        {
            "id": 26,
            "city": "Shivpuri"
        },
        {
            "id": 27,
            "city": "Guna"
        },
        {
            "id": 28,
            "city": "Gwalior"
        },
        {
            "id": 29,
            "city": "Datia"
        },
        {
            "id": 30,
            "city": "Bhind"
        },
        {
            "id": 31,
            "city": "Morena"
        },
        {
            "id": 32,
            "city": "Sheopur"
        },
        {
            "id": 33,
            "city": "Indore"
        },
        {
            "id": 34,
            "city": "Dewas"
        },
        {
            "id": 35,
            "city": "Dhar"
        },
        {
            "id": 36,
            "city": "Katni"
        },
        {
            "id": 37,
            "city": "Jabalpur"
        },
        {
            "id": 38,
            "city": "East Nimar"
        },{
            "id": "39",
            "city": "Barwani"
        },
        {
            "id": "40",
            "city": "Khandwa"
        },
        {
            "id": 41,
            "city": "Burhanpur"
        },
        {
            "id": 42,
            "city": "Khargone"
        },
        {
            "id": 43,
            "city": "Neemuch"
        },
        {
            "id": 44,
            "city": "Mandsaur"
        },
        {
            "id": 45,
            "city": "Jhabua"
        },
        {
            "id": 46,
            "city": "Ratlam"
        },
        {
            "id": 47,
            "city": "Alirajpur"
        },
        {
            "id": 48,
            "city": "Sehore"
        },
        {
            "id": 49,
            "city": "Rajgarh"
        },
        {
            "id": 50,
            "city": "Ujjain"
        },
        {
            "id": 51,
            "city": "Shajapur"
        }],
    "MAHARASHTRA":[ {
            "id": "1",
            "city": "Jalna"
        },
        {
            "id": "2",
            "city": "Aurangabad"
        },
        {
            "id": 3,
            "city": "Beed"
        },
        {
            "id": 4,
            "city": "Jalgaon"
        },
        {
            "id": 5,
            "city": "Dhule"
        },
        {
            "id": 6,
            "city": "Nandurbar"
        },
        {
            "id": 7,
            "city": "Nashik"
        },
        {
            "id": 8,
            "city": "Nanded"
        },
        {
            "id": 9,
            "city": "Latur"
        },
        {
            "id": 10,
            "city": "Osmanabad"
        },
        {
            "id": 11,
            "city": "Hingoli"
        },
        {
            "id": 12,
            "city": "Parbhani"
        },
        {
            "id": 13,
            "city": "Kolhapur"
        },
        {
            "id": 14,
            "city": "Ratnagiri"
        },
        {
            "id": 15,
            "city": "Sindhudurg"
        },
        {
            "id": 16,
            "city": "Satara"
        },
        {
            "id": 17,
            "city": "Sangli"
        },
        {
            "id": 18,
            "city": "Mumbai"
        },
        {
            "id": 19,
            "city": "Raigarh(MH)"
        },{
            "id": "20",
            "city": "Thane"
        },
        {
            "id": "21",
            "city": "Akola"
        },
        {
            "id": 22,
            "city": "Washim"
        },
        {
            "id": 23,
            "city": "Amravati"
        },
        {
            "id": 24,
            "city": "Buldhana"
        },
        {
            "id": 25,
            "city": "Gadchiroli"
        },
        {
            "id": 26,
            "city": "Chandrapur"
        },
        {
            "id": 27,
            "city": "Nagpur"
        },
        {
            "id": 28,
            "city": "Gondia"
        },
        {
            "id": 29,
            "city": "Bhandara"
        },
        {
            "id": 30,
            "city": "Wardha"
        },
        {
            "id": 31,
            "city": "Yavatmal"
        },
        {
            "id": 32,
            "city": "Ahmed Nagar"
        },
        {
            "id": 33,
            "city": "Solapur"
        },
        {
            "id": 34,
            "city": "Pune"
        }],
    "MANIPUR":[  {
            "id": "1",
            "city": "Imphal West"
        },
        {
            "id": "2",
            "city": "Churachandpur"
        },
        {
            "id": 3,
            "city": "Chandel"
        },
        {
            "id": 4,
            "city": "Thoubal"
        },
        {
            "id": 5,
            "city": "Tamenglong"
        },
        {
            "id": 6,
            "city": "Ukhrul"
        },
        {
            "id": 7,
            "city": "Imphal East"
        },
        {
            "id": 8,
            "city": "Bishnupur"
        },
        {
            "id": 9,
            "city": "Senapati"
        }],
    "MEGHALAYA":[  {
            "id": "1",
            "city": "West Garo Hills"
        },
        {
            "id": "2",
            "city": "East Garo Hills"
        },
        {
            "id": 3,
            "city": "Jaintia Hills"
        },
        {
            "id": 4,
            "city": "East Khasi Hills"
        },
        {
            "id": 5,
            "city": "South Garo Hills"
        },
        {
            "id": 6,
            "city": "Ri Bhoi"
        },
        {
            "id": 7,
            "city": "West Khasi Hills"
        }],
    "MIZORAM":[ {
            "id": "1",
            "city": "Aizawl"
        },
        {
            "id": "2",
            "city": "Mammit"
        },
        {
            "id": 3,
            "city": "Lunglei"
        },
        {
            "id": 4,
            "city": "Kolasib"
        },
        {
            "id": 5,
            "city": "Lawngtlai"
        },
        {
            "id": 6,
            "city": "Champhai"
        },
        {
            "id": 7,
            "city": "Saiha"
        },
        {
            "id": 8,
            "city": "Serchhip"
        }],
    "NAGALAND":[
        {
            "id": "1",
            "city": "Zunhebotto"
        },
        {
            "id": "2",
            "city": "Dimapur"
        },
        {
            "id": 3,
            "city": "Wokha"
        },
        {
            "id": 4,
            "city": "Phek"
        },
        {
            "id": 5,
            "city": "Mokokchung"
        },
        {
            "id": 6,
            "city": "Kiphire"
        },
        {
            "id": 7,
            "city": "Tuensang"
        },
        {
            "id": 8,
            "city": "Mon"
        },
        {
            "id": 9,
            "city": "Kohima"
        },
        {
            "id": 10,
            "city": "Peren"
        },
        {
            "id": 11,
            "city": "Longleng"
        }],
    "ODISHA":[{
            "id": "1",
            "city": "Ganjam"
        },
        {
            "id": "2",
            "city": "Gajapati"
        },
        {
            "id": 3,
            "city": "Kalahandi"
        },
        {
            "id": 4,
            "city": "Nuapada"
        },
        {
            "id": 5,
            "city": "Koraput"
        },
        {
            "id": 6,
            "city": "Rayagada"
        },
        {
            "id": 7,
            "city": "Nabarangapur"
        },
        {
            "id": 8,
            "city": "Malkangiri"
        },
        {
            "id": 9,
            "city": "Kandhamal"
        },
        {
            "id": 10,
            "city": "Boudh"
        },
        {
            "id": 11,
            "city": "Baleswar"
        },
        {
            "id": 12,
            "city": "Bhadrak"
        },
        {
            "id": 13,
            "city": "Kendujhar"
        },
        {
            "id": 14,
            "city": "Khorda"
        },
        {
            "id": 15,
            "city": "Puri"
        },
        {
            "id": 16,
            "city": "Cuttack"
        },
        {
            "id": 17,
            "city": "Jajapur"
        },
        {
            "id": 18,
            "city": "Kendrapara"
        },
        {
            "id": 19,
            "city": "Jagatsinghapur"
        },  {
            "id": "20",
            "city": "Mayurbhanj"
        },
        {
            "id": "21",
            "city": "Nayagarh"
        },
        {
            "id": 22,
            "city": "Balangir"
        },
        {
            "id": 23,
            "city": "Sonapur"
        },
        {
            "id": 24,
            "city": "Angul"
        },
        {
            "id": 25,
            "city": "Dhenkanal"
        },
        {
            "id": 26,
            "city": "Sambalpur"
        },
        {
            "id": 27,
            "city": "Bargarh"
        },
        {
            "id": 28,
            "city": "Jharsuguda"
        },
        {
            "id": 29,
            "city": "Debagarh"
        },
        {
            "id": 30,
            "city": "Sundergarh"
        }],
    "PONDICHERRY":[ {
            "id": 1,
            "city": "Pondicherry"
        },
        {
            "id": 2,
            "city": "Mahe"
        },
        {
            "id": 3,
            "city": "Karaikal"
        }],
    "PUNJAB":[ {
            "id": "1",
            "city": "Ropar"
        },
        {
            "id": "2",
            "city": "Mohali"
        },
        {
            "id": 3,
            "city": "Rupnagar"
        },
        {
            "id": 4,
            "city": "Patiala"
        },
        {
            "id": 5,
            "city": "Ludhiana"
        },
        {
            "id": 6,
            "city": "Fatehgarh Sahib"
        },
        {
            "id": 7,
            "city": "Sangrur"
        },
        {
            "id": 8,
            "city": "Barnala"
        },
        {
            "id": 9,
            "city": "Amritsar"
        },
        {
            "id": 10,
            "city": "Tarn Taran"
        },
        {
            "id": 11,
            "city": "Bathinda"
        },
        {
            "id": 12,
            "city": "Mansa"
        },
        {
            "id": 13,
            "city": "Muktsar"
        },
        {
            "id": 14,
            "city": "Moga"
        },
        {
            "id": 15,
            "city": "Faridkot"
        },
        {
            "id": 16,
            "city": "Firozpur"
        },
        {
            "id": 17,
            "city": "Fazilka"
        },
        {
            "id": 18,
            "city": "Gurdaspur"
        },
        {
            "id": 19,
            "city": "Pathankot"
        },{
            "id": "20",
            "city": "Hoshiarpur"
        },
        {
            "id": "21",
            "city": "Nawanshahr"
        },
        {
            "id": 22,
            "city": "Jalandhar"
        },
        {
            "id": 23,
            "city": "Kapurthala"
        }],
    "RAJASTHAN":[{
            "id": "1",
            "city": "Ajmer"
        },
        {
            "id": "2",
            "city": "Rajsamand"
        },
        {
            "id": 3,
            "city": "Bhilwara"
        },
        {
            "id": 4,
            "city": "Chittorgarh"
        },
        {
            "id": 5,
            "city": "Banswara"
        },
        {
            "id": 6,
            "city": "Dungarpur"
        },
        {
            "id": 7,
            "city": "Kota"
        },
        {
            "id": 8,
            "city": "Baran"
        },
        {
            "id": 9,
            "city": "Jhalawar"
        },
        {
            "id": 10,
            "city": "Bundi"
        },
        {
            "id": 11,
            "city": "Tonk"
        },
        {
            "id": 12,
            "city": "Udaipur"
        },
        {
            "id": 13,
            "city": "Alwar"
        },
        {
            "id": 14,
            "city": "Bharatpur"
        },
        {
            "id": 15,
            "city": "Dholpur"
        },
        {
            "id": 16,
            "city": "Jaipur"
        },
        {
            "id": 17,
            "city": "Dausa"
        },
        {
            "id": 18,
            "city": "Sawai Madhopur"
        },
        {
            "id": 19,
            "city": "Karauli"
        },   {
            "id": "20",
            "city": "Barmer"
        },
        {
            "id": "21",
            "city": "Bikaner"
        },
        {
            "id": 22,
            "city": "Churu"
        },
        {
            "id": 23,
            "city": "Jhujhunu"
        },
        {
            "id": 24,
            "city": "Jodhpur"
        },
        {
            "id": 25,
            "city": "Jaisalmer"
        },
        {
            "id": 26,
            "city": "Nagaur"
        },
        {
            "id": 27,
            "city": "Pali"
        },
        {
            "id": 28,
            "city": "Sikar"
        },
        {
            "id": 29,
            "city": "Sirohi"
        },
        {
            "id": 30,
            "city": "Jalor"
        },
        {
            "id": 31,
            "city": "Ganganagar"
        },
        {
            "id": 32,
            "city": "Hanumangarh"
        }],
    "SIKKIM":[{
            "id": "1",
            "city": "East Sikkim"
        },
        {
            "id": "2",
            "city": "West Sikkim"
        },
        {
            "id": "3",
            "city": "South Sikkim"
        },
        {
            "id": "4",
            "city": "North Sikkim"
        }],
    "TAMIL NADU":[{
            "id": "1",
            "city": "Chennai"
        },
        {
            "id": "2",
            "city": "Vellore"
        },
        {
            "id": 3,
            "city": "Tiruvannamalai"
        },
        {
            "id": 4,
            "city": "Kanchipuram"
        },
        {
            "id": 5,
            "city": "Tiruvallur"
        },
        {
            "id": 6,
            "city": "Villupuram"
        },
        {
            "id": 7,
            "city": "Cuddalore"
        },
        {
            "id": 8,
            "city": "Coimbatore"
        },
        {
            "id": 9,
            "city": "Dharmapuri"
        },
        {
            "id": 10,
            "city": "Salem"
        },
        {
            "id": 11,
            "city": "Erode"
        },
        {
            "id": 12,
            "city": "Karur"
        },
        {
            "id": 13,
            "city": "Namakkal"
        },
        {
            "id": 14,
            "city": "Krishnagiri"
        },
        {
            "id": 15,
            "city": "Nilgiris"
        },
        {
            "id": 16,
            "city": "Dindigul"
        },
        {
            "id": 17,
            "city": "Kanyakumari"
        },
        {
            "id": 18,
            "city": "Sivaganga"
        },
        {
            "id": 19,
            "city": "Ramanathapuram"
        },{
            "id": "20",
            "city": "Tuticorin"
        },
        {
            "id": "21",
            "city": "Tirunelveli"
        },
        {
            "id": 22,
            "city": "Madurai"
        },
        {
            "id": 23,
            "city": "Theni"
        },
        {
            "id": 24,
            "city": "Virudhunagar"
        },
        {
            "id": 25,
            "city": "Ariyalur"
        },
        {
            "id": 26,
            "city": "Tiruchirappalli"
        },
        {
            "id": 27,
            "city": "Pudukkottai"
        },
        {
            "id": 28,
            "city": "Tiruvarur"
        },
        {
            "id": 29,
            "city": "Thanjavur"
        },
        {
            "id": 30,
            "city": "Nagapattinam"
        },
        {
            "id": 31,
            "city": "Perambalur"
        }],
    "TELANGANA":[{
            "id": "1",
            "city": "Adilabad"
        },
        {
            "id": "2",
            "city": "Warangal"
        },
        {
            "id": 3,
            "city": "Karim Nagar"
        },
        {
            "id": 4,
            "city": "Mahabub Nagar"
        },
        {
            "id": 5,
            "city": "K.V.Rangareddy"
        },
        {
            "id": 6,
            "city": "Medak"
        },
        {
            "id": 7,
            "city": "Nalgonda"
        },
        {
            "id": 8,
            "city": "Nizamabad"
        },
        {
            "id": 9,
            "city": "Hyderabad"
        },
        {
            "id": 10,
            "city": "Khammam"
        }],
    "TRIPURA":[{
            "id": "1",
            "city": "South Tripura"
        },
        {
            "id": "2",
            "city": "West Tripura"
        },
        {
            "id": "3",
            "city": "Dhalai"
        },
        {
            "id": "4",
            "city": "North Tripura"
        }],
    "UTTAR PRADESH":[{
            "id": "1",
            "city": "Agra"
        },
        {
            "id": "2",
            "city": "Aligarh"
        },
        {
            "id": 3,
            "city": "Hathras"
        },
        {
            "id": 4,
            "city": "Bulandshahr"
        },
        {
            "id": 5,
            "city": "Gautam Buddha Nagar"
        },
        {
            "id": 6,
            "city": "Etah"
        },
        {
            "id": 7,
            "city": "Firozabad"
        },
        {
            "id": 8,
            "city": "Etawah"
        },
        {
            "id": 9,
            "city": "Auraiya"
        },
        {
            "id": 10,
            "city": "Jhansi"
        },
        {
            "id": 11,
            "city": "Jalaun"
        },
        {
            "id": 12,
            "city": "Lalitpur"
        },
        {
            "id": 13,
            "city": "Mainpuri"
        },
        {
            "id": 14,
            "city": "Mathura"
        },
        {
            "id": 15,
            "city": "Azamgarh"
        },
        {
            "id": 16,
            "city": "Allahabad"
        },
        {
            "id": 17,
            "city": "Kaushambi"
        },
        {
            "id": 18,
            "city": "Ghazipur"
        },
        {
            "id": 19,
            "city": "Jaunpur"
        },{
            "id": "20",
            "city": "Sonbhadra"
        },
        {
            "id": "21",
            "city": "Mirzapur"
        },
        {
            "id": 22,
            "city": "Pratapgarh"
        },
        {
            "id": 23,
            "city": "Varanasi"
        },
        {
            "id": 24,
            "city": "Chandauli"
        },
        {
            "id": 25,
            "city": "Sant Ravidas Nagar"
        },
        {
            "id": 26,
            "city": "Pilibhit"
        },
        {
            "id": 27,
            "city": "Bareilly"
        },
        {
            "id": 28,
            "city": "Bijnor"
        },
        {
            "id": 29,
            "city": "Budaun"
        },
        {
            "id": 30,
            "city": "Hardoi"
        },
        {
            "id": 31,
            "city": "Kheri"
        },
        {
            "id": 32,
            "city": "Meerut"
        },
        {
            "id": 33,
            "city": "Bagpat"
        },
        {
            "id": 34,
            "city": "Moradabad"
        },
        {
            "id": 35,
            "city": "Jyotiba Phule Nagar"
        },
        {
            "id": 36,
            "city": "Rampur"
        },
        {
            "id": 37,
            "city": "Muzaffarnagar"
        },
        {
            "id": 38,
            "city": "Saharanpur"
        },{
            "id": "39",
            "city": "Mau"
        },
        {
            "id": "40",
            "city": "Shrawasti"
        },
        {
            "id": 41,
            "city": "Bahraich"
        },
        {
            "id": 42,
            "city": "Ballia"
        },
        {
            "id": 43,
            "city": "Siddharthnagar"
        },
        {
            "id": 44,
            "city": "Sant Kabir Nagar"
        },
        {
            "id": 45,
            "city": "Basti"
        },
        {
            "id": 46,
            "city": "Deoria"
        },
        {
            "id": 47,
            "city": "Kushinagar"
        },
        {
            "id": 48,
            "city": "Gonda"
        },
        {
            "id": 49,
            "city": "Balrampur"
        },
        {
            "id": 50,
            "city": "Gorakhpur"
        },
        {
            "id": 51,
            "city": "Maharajganj"
        },
        {
            "id": 52,
            "city": "Banda"
        },
        {
            "id": 53,
            "city": "Chitrakoot"
        },
        {
            "id": 54,
            "city": "Mahoba"
        },
        {
            "id": 55,
            "city": "Hamirpur"
        },
        {
            "id": 56,
            "city": "Kannauj"
        },
        {
            "id": 57,
            "city": "Farrukhabad"
        }, {
            "id": "58",
            "city": "Fatehpur"
        },
        {
            "id": "59",
            "city": "Kanpur Nagar"
        },
        {
            "id": 60,
            "city": "Unnao"
        },
        {
            "id": 61,
            "city": "Kanpur Dehat"
        },
        {
            "id": 62,
            "city": "Barabanki"
        },
        {
            "id": 63,
            "city": "Faizabad"
        },
        {
            "id": 64,
            "city": "Ambedkar Nagar"
        },
        {
            "id": 65,
            "city": "Ghaziabad"
        },
        {
            "id": 66,
            "city": "Lucknow"
        },
        {
            "id": 67,
            "city": "Raebareli"
        },
        {
            "id": 68,
            "city": "Sitapur"
        },
        {
            "id": 69,
            "city": "Sultanpur"
        }],
    "UTTARAKHAND":[ {
            "id": "1",
            "city": "Haridwar"
        },
        {
            "id": "2",
            "city": "Almora"
        },
        {
            "id": 3,
            "city": "Bageshwar"
        },
        {
            "id": 4,
            "city": "Chamoli"
        },
        {
            "id": 5,
            "city": "Rudraprayag"
        },
        {
            "id": 6,
            "city": "Dehradun"
        },
        {
            "id": 7,
            "city": "Udham Singh Nagar"
        },
        {
            "id": 8,
            "city": "Nainital"
        },
        {
            "id": 9,
            "city": "Champawat"
        },
        {
            "id": 10,
            "city": "Pauri Garhwal"
        },
        {
            "id": 11,
            "city": "Pithoragarh"
        },
        {
            "id": 12,
            "city": "Tehri Garhwal"
        },
        {
            "id": 13,
            "city": "Uttarkashi"
        }],
    "WEST BENGAL":[{
            "id": "1",
            "city": "Kolkata"
        },
        {
            "id": "2",
            "city": "North 24 Parganas"
        },
        {
            "id": 3,
            "city": "South 24 Parganas"
        },
        {
            "id": 4,
            "city": "Birbhum"
        },
        {
            "id": 5,
            "city": "Murshidabad"
        },
        {
            "id": 6,
            "city": "Nadia"
        },
        {
            "id": 7,
            "city": "Cooch Behar"
        },
        {
            "id": 8,
            "city": "Jalpaiguri"
        },
        {
            "id": 9,
            "city": "Darjiling"
        },
        {
            "id": 10,
            "city": "Malda"
        },
        {
            "id": 11,
            "city": "South Dinajpur"
        },
        {
            "id": 12,
            "city": "North Dinajpur"
        },
        {
            "id": 13,
            "city": "Bardhaman"
        },
        {
            "id": 14,
            "city": "Bankura"
        },
        {
            "id": 15,
            "city": "West Midnapore"
        },
        {
            "id": 16,
            "city": "East Midnapore"
        },
        {
            "id": 17,
            "city": "Hooghly"
        },
        {
            "id": 18,
            "city": "Howrah"
        },
        {
            "id": 19,
            "city": "Medinipur"
        },{"id":20,"city":"Puruliya"}]
    }
