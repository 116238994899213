import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../login.service';
import { environment as env } from '../../../environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-upload-history',
  templateUrl: './upload-history.component.html',
  styleUrls: ['./upload-history.component.scss']
})
export class UploadHistoryComponent implements OnInit {

  token: any;
  chunkData: any;
  data: any;
  total: number = 0;
  page: number = 1;
  pageSize = 6;
  enableUserDropdown = false;
  headers: any;
  jsonData: any;
  fileName: any;
  filterForm: any;
  submittedCampaignForm: boolean = false;
  showDateErrorMsg: boolean = false;
  passwordNotMaching: boolean = false;
  uploadId:any
  campaignId:any
  constructor(
    private apiService: LoginService,
    private router: Router,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.uploadId = this.route.snapshot.params['data'];
    this.campaignId = this.route.snapshot.params['campaignId']
  }
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getChunkDataByUploadId();
    }
    this.filterForm = this.formBuilder.group({
      inactive: [null],
      active: [null],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  get campaignFormVal() {
    return this.filterForm.controls;
  }
  url = env.url;

  searchKey: any = '';
  searchData(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    this.apiService
      .getChunkDataByUploadId(this.uploadId)
      .subscribe(
        (res: any) => {
          if (res['status'] == 'success') {
            this.chunkData = res['result'];
            this.total = this.chunkData.length;
          }
        },
        (err) => {
          this.toaster.error(err.error.message);
        }
      );
  }

  isShown: boolean = false; // hidden by default

  toggleShow() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isShown = !this.isShown;
    } else {
    }
  }

  goToBack(){
    this.router.navigate([`/customer-upload`,{ data: this.campaignId }]);

  }

  getChunkDataByUploadId() {
    this.apiService.getChunkDataByUploadId(this.uploadId).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.chunkData = res['result'];
          this.total = this.chunkData.length;
        }
      },
      (err) => {
        this.toaster.error(err.error.message);
      }
    );
  }

  pageChangeEvent(event: number) {
    this.page = event;
    if (this.searchKey) {
      this.searchData();
    } else {
      this.getChunkDataByUploadId();
    }
  }
  showDropDown() {
    this.enableUserDropdown = !this.enableUserDropdown;
  }

  
  download() {
    this.apiService.downloadFile(this.jsonData, this.fileName, this.headers);
  }

}
