import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
})
export class StoreListComponent implements OnInit {
  storeList: any;
  constructor(
    private apiService: LoginService,
    private toaster: ToastrService,
    private router: Router
  ) {}
  page: number = 1;
  pageSize = 6;
  total: number = 0;
  isShown: boolean = false; // hidden by default
  token: any
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if(this.token){
    this.getStore();
    }
  }
  pageChangeEvent(event: number) {
    this.page = event;
    if (this.searchKey) {
      this.searchData();
    } else {
      this.getStore();
    }
  }
  getStore() {
    this.apiService.getStoreList(this.pageSize, this.page, this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.storeList = res['result'].data;
          this.total = res['result'].count;
        }
      },
      (err) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  getStaffId(id: any) {
    let data = {
      data: id,
    };
    this.router.navigate(['/store', data]);
  }
  addStore() {
    this.router.navigate(['/add-store']);
  }
  searchKey: any = '';
  searchData(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    this.apiService
      .search('store', this.searchKey, this.pageSize, this.page, this.token)
      .subscribe(
        (res: any) => {
          if (res['status'] == 'success') {
            this.storeList = res['result'].data;
            this.total = res['result'].count;
          }
        },
        (err) => {
          this.toaster.error(err.error.message);
        }
      );
  }
}
