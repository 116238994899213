import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './services/api.service';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient, private toaster: ToastrService, private apiService:ApiService) {}

  showToaster(msg: any) {
    this.toaster.info(msg);
  }

  otpValidate(formData: any) {
    
    return this.apiService.post('otp/validate',formData);
    
  }

  getCampaignList(url: any) {
    return this.apiService.get(url)
    
  }
  logout() {
    return this.apiService.put(`otp/logout`,null)
   
  }
  getCampaign(id: any) {
    return this.apiService.get(`campaign/single-campaign/${id}`)
    
  }
  getCampaignPriority(campaignId: any) {
    return this.apiService.get(`campaign/${campaignId}`);
  }
  
  getSignedUrl(file: File) {
    return this.apiService.get(`getSignedUrl/?key=${file.name}`)
  }

  readSignedUrl(url:string) {
    return this.apiService.get(url)
  }

  getChunkDataByUploadId(id:string) {
    return this.apiService.get(`customer/chunk-list?uploadId=${id}`)
  }

  getCustomerUploadsByCampaignId(id:string) {
    return this.apiService.get(`customer/uploads-list?campaignId=${id}`)
  }

  getErrorFile(id:string){
    return this.apiService.get(`customer/error-file/${id}`)
  }

  uploadFileToS3(signedUrl:string,file:Blob){
    return this.http.put(signedUrl, file)
  }

  uploadDetailsAndChunkCreation(postData:any){
    return this.apiService.post('customer/customer-upload-create-chunk',postData)
  }


  uploadChunkData(postData:any){
    return this.apiService.post('customer/customer-chunk-upload',postData)
  }

  importBulkInsertToStaff(token: any, formData: FormData){
    return this.http.post(`${env.BASE_URL_IMAGE}bulkCreateOrUpdate`, formData, {
      headers: new HttpHeaders({
        'x-user-token': token,
      }),
    });
    
  }


  importData(token: any, formData: FormData) {
    return this.http.post(`${env.BASE_URL_IMAGE}/uploadFile`, formData, {
      headers: new HttpHeaders({
        'x-user-token': token,
      }),
    });
  }

  addCampaign(token: any, formData: any) {
    return this.apiService.post(`campaign/add`,formData);
    
  }
  editCampaign(token: any, formData: any, campaignId: any) {
    return this.apiService.put(`campaign/edit/${campaignId}`,formData);
    
  }
  addCampaignImage(token: any, formData: any, id: any) {
    return this.apiService.post(`upload-campaign-image/${id}`,formData);
  }
  addCampaignPriorities(token: any, priorities: any, id: any) {
    return this.apiService.post(`campaign/add-priority/${id}`,{priorities});
  }

  getAllPriority(token: any) {
    return this.apiService.get("priority");
    
  }
  getStaffList(limit: number, page: number, token: any) {
    return this.apiService.get(`employee/list?limit=${limit}&page=${page}`);
   
  }
  getStaff(id: any, token: any) {
    return this.apiService.get(`employee/${id}`);
  }
  deleteStaff(id: any, token: any) {
    return this.apiService.delete(`employee/delete/${id}`);
   
  }
  editStaff(id: any, formData: any, token: any) {
    return this.apiService.put(`employee/edit/${id}`,formData);
  }

  getStoreList(limit: number, page: number, token: any) {
    return this.apiService.get(`store/list?limit=${limit}&page=${page}`);
  }

  getStoreListStaff(staff: any, token: any) {
    return this.apiService.get(`store/list?staff=${staff}`);
  }

  addStaff(formData: any, token: any) {
    return this.apiService.post(`employee/add`,formData);
    
  }
  getStore(id: any, token: any) {
    return this.apiService.get(`store/${id}`)
  }

  editStore(id: any, formData: any, token: any) {
    return this.apiService.put(`store/edit/${id}`,formData)
  }

  addStore(formData: any, token: any) {
    return this.apiService.post(`store/add`,formData)
  }

  search(type: any, key: any, limit: any, page: any, token: any) {
    return this.apiService.post(`campaign/search?limit=${limit}&page=${page}`,{ type, key })
  }

  checkToken() {
    let token = localStorage.getItem('token');
    if (!token) {
      return this.toaster.error(`Token Not Found..`);
    }
    return token;
  }
  exportReport(id: any, token: any) {
    return this.apiService.get(`campaign/report/${id}`);
  }

  filterCampaign(active: true, date: any, token: any) {
    return this.apiService.get(`campaign?date=${date}&is_active=${active}`)
  }

  getPriorities() {
    return this.apiService.get("priority")
  }

  addPriorities(formData: any) {
    return this.apiService.post("priority/add",formData)
   
  }

  editPriorities(id: any, formData: any) {
    return this.apiService.put(`priority/edit/${id}`,formData);
  }

  bulkInsertCustomerData(data: any) {
    return this.apiService.post(`customer/customer-bulk-create-update`,data);
  }

  bulkInsertStaffData(data: any) {
    return this.apiService.post(`employee/staff-bulk-create-update`,data);
  }

 

  downloadS3File = function(url:any){
    var a = document.createElement("a");
    a.href = url;
    a.download = "fileName";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
  


  downloadFile(data: any, filename = 'data', headers: any) {
    let csvData = this.ConvertToCSV(data, headers);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  exportCustomer(token: any, campaignId: any, limit: any, page: any) {
    const someVal = `campaign/customers/${campaignId}?limit=${limit}&page=${page}`;
    return this.apiService.get(`campaign/customers/${campaignId}?limit=${limit}&page=${page}`)
  }
}
