<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-priority"
            role="tabpanel"
            aria-labelledby="v-pills-priority-tab"
          >
            <div class="edit-priority-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1 d-flex align-items-center">
                  <h1 class="main-heading">Edit Priority</h1>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                  <button
                    class="btn-primary-sm btn-grey shine cancel"
                    (click)="onCancel()"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn-primary-sm btn-blue ml20 shine save"
                    (click)="onSave()"
                  >
                    Save
                  </button>
                </div>
              </div>

              <div
                class="d-flex flex-wrap justify-content-between mt20 customForm"
              >
                <div class="form-group form-item">
                  <form [formGroup]="editPriorityForm">
                    <label for="customField1">Priority Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder=""
                      formControlName="priorityname"
                      [(ngModel)]="name"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
