import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { environment as env } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { parse } from 'papaparse';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
})
export class CampaignDetailsComponent implements OnInit {
  campaignId: any;
  jsonData: any = [];
  token: any;
  campaign: any;
  campaignPriorities: any;
  isUploading: boolean = true;
  count: number = 0;
  headers: any = [];
  fileName: any;
  progressPercentage:any =  0;
  BATCH_SIZE = 20000;
  main:any;


  @ViewChild('file', { static: false })
  myInputVariable!: ElementRef;

  // this InputVar is a reference to our input.
  inputVar: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: LoginService,
    private toaster: ToastrService,
    private http: HttpClient
  ) {
    this.campaignId = this.route.snapshot.params['data'];
    this.main = {
      isBar:true,
      bar2ProgressVal:0,
      bar2:null
    }
  }
  url = env.url;
  limit = 20000;
  concurrencyLimit = 6;
  pages: any;
  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.getCampaign();
  }



  getCampaign() {
    this.apiService.getCampaign(this.campaignId).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.campaign = res['result'].capData;
          this.count = res['result'].count ? res['result'].count : 0;
          this.pages = Math.ceil(this.count / this.limit);
        }
      },
      (err) => {
        this.toaster.error(err.message);
      }
    );
    this.apiService.getCampaignPriority(this.campaignId).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.campaignPriorities = res['result'];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  goToBack() {
    this.router.navigate(['/campaign']);
  }

  redirectToUploadHistory(){
    this.router.navigate([`/customer-upload`,{ data: this.campaignId }]);
  }

  async processBatch(completedChunks:any,campaignId:any,chunkId:any,uploadId:any,chunkData:any,totalChunks:any,lastCall=false) {
    // Perform some processing on the batch here, if necessary
    return new Promise((resolve, reject) => {
       // Wait for some async operation to complete, if necessary
    this.apiService.uploadChunkData({campaignId:campaignId,chunkId:chunkId,uploadId:uploadId,records:chunkData}).subscribe(()=>{
      this.progressPercentage = ((completedChunks / totalChunks) * 100).toFixed(0);
      resolve(completedChunks)
    },(err:any) => {
      this.progressPercentage = ((completedChunks / totalChunks) * 100).toFixed(0);
      resolve(completedChunks)
    })
    })
  }


  async parseCsv(file: File|any, chunks:any,campaignId:any) {
    let completedChunks:any = 1;
    let concurrencyRequests: any[] = []
    let totalChunks = chunks.length
    return new Promise<any[]>(async (resolve) => {
      parse(file, {
        header: true,
        skipEmptyLines: true,
        worker:true,
        chunkSize: this.BATCH_SIZE,
        complete: async (results) => {
          for(let i=0;i<totalChunks;i++){
            const chunkData = results.data.slice(chunks[i].fromRecord-1,chunks[i].toRecord)
            const lastCall = i+1===totalChunks
            concurrencyRequests.push(this.processBatch(completedChunks,campaignId,chunks[i].id,chunks[i].upload_id,chunkData,totalChunks,lastCall))
            try{
            if(concurrencyRequests.length === this.concurrencyLimit || lastCall){
              await Promise.all(concurrencyRequests);
              concurrencyRequests = []
              completedChunks = completedChunks + this.concurrencyLimit;
              if(lastCall){
                this.progressPercentage = 100
                this.isUploading = true;
                this.myInputVariable.nativeElement.value = '';
                this.getCampaign()
                this.toaster.success("Processing of the uploaded data is complete. Please check the upload history to view its status.");
                this.progressPercentage = 0
              }
            }
          }catch(error){
            console.log("Error",error)
          }
            
          }
          resolve(results.data);
        }
      });
    });
  }

  

 
  readChunkFilesAndUploadChunkData(file: any, chunks: any,campaignId:any) {
    const data = this.parseCsv(file,chunks,campaignId);
  }

  handleFileInput(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = event.target.files[0].name;
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('campaign_id', this.campaignId);
      this.isUploading = false;
      this.apiService.getSignedUrl(file).subscribe((res: any) => {
        this.apiService
          .uploadFileToS3(res.result?.putUrl, file)
          .subscribe((uploadRes: any) => {
            const signedUrl = res.result?.getUrl;
            this.apiService
              .uploadDetailsAndChunkCreation({
                url: signedUrl,
                campaignId: this.campaignId,
              })
              .subscribe(
                (chunks: any) => {
                  if (chunks.status === 'success') {
                    this.readChunkFilesAndUploadChunkData(
                      file,
                      chunks?.result?.chunks,
                      this.campaignId
                    );
                  } else {
                    this.myInputVariable.nativeElement.value = '';
                    this.toaster.error(chunks?.error?.message);
                  }
                },
                (err: any) => {
                  this.isUploading = true;
                  this.myInputVariable.nativeElement.value = '';
                }
              );
          }),
          (err: any) => {
            this.isUploading = true;
            this.myInputVariable.nativeElement.value = '';
          };
      }),
        (err: any) => {
          this.isUploading = true;
          this.myInputVariable.nativeElement.value = '';
        };
    }
  }

  editCampaign() {
    this.router.navigate(['/edit-campaign-detail', { data: this.campaignId }]);
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isShown = !this.isShown;
    } else {
      console.log('not mobile');
    }
  }
  exportReport() {
    this.apiService.exportReport(this.campaignId, this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          if (res.result.length > 0) {
            this.headers = Object.keys(res.result[0]);
            this.jsonData = res.result;
            this.fileName = `export_campaign_${this.campaignId}_data`;
            this.download();
          } else {
            this.toaster.success(`THERE IS NO DATA TO EXPORT FOR CAMPAIGN`);
          }
        }
      },
      (err) => {
        this.toaster.error(`Some error while export report`);
      }
    );
  }

  download(i = 0) {
    this.apiService.downloadFile(this.jsonData, this.fileName, this.headers);
  }

  exportCustomer() {
    let pages = Math.ceil(this.count / this.limit);
    if (pages > 0) {
      for (let ind = 0; ind < pages; ind++) {
        this.isUploading = false;
        this.apiService
          .exportCustomer(this.token, this.campaignId, this.limit, 1)
          .subscribe(
            (res: any) => {
              if (res[`status`] == 'success') {
                if (res.result) {
                  this.headers = Object.keys(res.result[0]);
                  if (
                    Object.keys(res.result[0]?.original_customfield).length > 0
                  ) {
                    const customHeaders = Object.keys(
                      res?.result[0]?.original_customfield
                    );
                    const updatedCustomHeaders: {
                      columnName: string;
                      updatedColumnName: string;
                    }[] = [];
                    customHeaders.forEach((o) => {
                      updatedCustomHeaders.push({
                        columnName: o,
                        updatedColumnName: `original_customfield_${o}`,
                      });
                      updatedCustomHeaders.push({
                        columnName: o,
                        updatedColumnName: `edited_customfield_${o}`,
                      });
                    });
                    const originalCustomfieldIndex = this.headers.findIndex(
                      (o: string) => o === `original_customfield`
                    );
                    const customFields = updatedCustomHeaders.map(
                      ({ updatedColumnName }) => updatedColumnName
                    );
                    this.headers.splice(
                      originalCustomfieldIndex,
                      2,
                      ...customFields
                    );
                    for (let index = 0; index < res.result.length; index++) {
                      const row = res.result[index];

                      customFields.forEach((o, i) => {
                        const originalCustomfieldValue =
                          row.original_customfield;
                        const editedCustomfieldValue = row.edited_customfield;

                        if (i % 2 === 0) {
                          row[o] = originalCustomfieldValue
                            ? originalCustomfieldValue[
                                updatedCustomHeaders[i].columnName
                              ]
                            : '';
                        } else {
                          row[o] = editedCustomfieldValue
                            ? editedCustomfieldValue[
                                updatedCustomHeaders[i].columnName
                              ]
                            : '';
                        }
                      });
                    }
                  }
                  this.jsonData = res.result;
                  this.fileName = `export_campaign_${
                    this.campaign.campaign_name
                  }_data_${1}`;
                  this.download();
                  if (ind == pages - 1) {
                    this.isUploading = true;
                  }
                }
              }
            },
            (err) => {
              this.toaster.error(`ERROR OCCURED FOR PAGE ${ind + 1}`);
            }
          );
      }
    } else {
      this.toaster.success(`THERE IS NO DATA TO EXPORT FOR CUSTOMER`);
    }
  }
}
