import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private router: Router, private apiService: LoginService) {}
  token = localStorage.getItem('token');
  ngOnInit(): void {}
  logOut() {
    this.apiService.logout().subscribe((res: any) => {
      console.log(res);
      this.router.navigateByUrl('/login');
    });
    localStorage.clear();
  }

  isShown: boolean = false; // hidden by default

  toggleShow() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isShown = !this.isShown;
    } else {
      console.log('not mobile');
    }
  }
}
