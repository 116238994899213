<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-campaign" role="tabpanel"
            aria-labelledby="v-pills-campaign-tab">
            <div class="add-campaign-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1 d-flex align-items-center">
                  <h1 class="main-heading">Add Campaign</h1>
                </div>
                <form [formGroup]="campaignForm" (ngSubmit)="onSubmitCampaignForm()">
                  <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                    <button class="btn-primary-sm btn-grey shine cancel" (click)="goToBack()">
                      Cancel
                    </button>
                    <button class="btn-primary-sm btn-blue ml20 shine save" type="submit">
                      Save
                    </button>
                  </div>
                  <div class="d-flex flex-wrap mt20">
                    <div class="flex-grow-1">
                      <div class="d-flex status-div">
                        <p>Status :</p>

                        <label class="switch ml10">
                          <input type="checkbox" checked formControlName="is_active" />
                          <span class="slider round"></span>
                        </label>
                        <p class="ml10 active" *ngIf="campaignForm.value.is_active">
                          Active
                        </p>
                        <p class="ml10 active" *ngIf="!campaignForm.value.is_active">
                          Inactive
                        </p>
                      </div>
                    </div>
                    <div class="flex-grow-1 d-flex justify-content-end text-red">
                      * Marked fields are mandatory.
                    </div>
                  </div>

                  <div class="d-flex flex-wrap justify-content-between mt20 customForm">
                    <div class="form-group form-item">
                      <label for="customField1">Campaign Name <span class="text-red">*</span></label>
                      <input type="text" class="form-control" id="customField1" aria-describedby="customField1"
                        placeholder="Campaign Name" formControlName="campaign_name"
                        (keypress)="onlyAlphaNumeric($event)" [ngClass]="{
                          'is-invalid':
                            submittedCampaignForm &&
                            campaignFormVal.campaign_name.errors
                        }" />
                      <div *ngIf="
                          submittedCampaignForm &&
                          campaignFormVal.campaign_name.errors
                        " class="error">
                        <span *ngIf="campaignFormVal.campaign_name.errors.required" style="color: red">Campaign Name is
                          required</span>
                      </div>
                      <label for="customField1">Description <span class="text-red">*</span></label>
                      <textarea class="form-control" placeholder="Type Description" rows="7"
                        formControlName="campaign_message" (keypress)="onlyAlphaNumeric($event)" [ngClass]="{
                          'is-invalid':
                            submittedCampaignForm &&
                            campaignFormVal.campaign_message.errors
                        }"></textarea>
                      <div *ngIf="
                          submittedCampaignForm &&
                          campaignFormVal.campaign_message.errors
                        " class="error">
                        <span *ngIf="
                            campaignFormVal.campaign_message.errors.required
                          " style="color: red">Description is required</span>
                      </div>
                    </div>
                    <div class="form-group form-item upload-img">
                      <label for="customField2">Please upload image in the ration of 16:9</label>
                      <img class="w-100" [src]="image" alt="uplaod" />
                      <div class="file-upload">
                        <input class="form-control" type="file" id="formFile"
                          accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                          (change)="handleFileInput($event)" formControlName="campaign_image" />
                      </div>
                    </div>

                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField1">Custom Field 1</label>
                          <input type="text" class="form-control" id="customField1" aria-describedby="customField1"
                          placeholder="Type custom field (short text)" formControlName="custom_field_1"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField1Checkbox"
                              formControlName="custom_field_1_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField1Type">Type</label>
                          <select class="form-select" aria-label="customField1Type"
                            formControlName="custom_field_1_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField2">Custom Field 2</label>
                          <input type="text" class="form-control" id="customField2"
                            placeholder="Type custom field (short text)" formControlName="custom_field_2"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField2Checkbox"
                              formControlName="custom_field_2_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6 form-item">
                          <label for="customField2Type">Type</label>
                          <select class="form-select" aria-label="customField2Type"
                            formControlName="custom_field_2_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField3">Custom Field 3</label>
                          <input type="text" class="form-control" id="customField3" aria-describedby="customField1"
                          placeholder="Type custom field (short text)" formControlName="custom_field_3" (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField3Checkbox"
                              formControlName="custom_field_3_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6 form-item">
                          <label for="customField3Type">Type</label>
                          <select class="form-select" aria-label="customField3Type"
                            formControlName="custom_field_3_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField4">Custom Field 4</label>
                          <input type="text" class="form-control" id="customField4" placeholder="Type custom field (short text)"
                            formControlName="custom_field_4" (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField4Checkbox"
                              formControlName="custom_field_4_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField4Type">Type</label>
                          <select class="form-select" aria-label="customField4Type"
                            formControlName="custom_field_4_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField5">Custom Field 5</label>
                          <input type="text" class="form-control" id="customField5"
                            placeholder="Type custom field (short text)" formControlName="custom_field_5"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField5Checkbox"
                              formControlName="custom_field_5_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField5Type">Type</label>
                          <select class="form-select" aria-label="customField5Type"
                            formControlName="custom_field_5_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField6">Custom Field 6</label>
                          <input type="text" class="form-control" id="customField6" placeholder="Type custom field (short text)"
                            formControlName="custom_field_6" (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField6Checkbox"
                              formControlName="custom_field_6_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField6Type">Type</label>
                          <select class="form-select" aria-label="customField6Type"
                            formControlName="custom_field_6_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField7">Custom Field 7</label>
                          <input type="text" class="form-control" id="customField7"
                            placeholder="Type custom field (short text)" formControlName="custom_field_7"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField7Checkbox"
                              formControlName="custom_field_7_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField7Type">Type</label>
                          <select class="form-select" aria-label="customField7Type"
                            formControlName="custom_field_7_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField8">Custom Field 8</label>
                          <input type="text" class="form-control" id="customField8"
                            placeholder="Type custom field (short text)" formControlName="custom_field_8"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField8Checkbox"
                              formControlName="custom_field_8_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField8Type">Type</label>
                          <select class="form-select" aria-label="customField8Type"
                            formControlName="custom_field_8_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField9">Custom Field 9</label>
                          <input type="text" class="form-control" id="customField9"
                            placeholder="Type custom field (short text)" formControlName="custom_field_9"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField9Checkbox"
                              formControlName="custom_field_9_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField9Type">Type</label>
                          <select class="form-select" aria-label="customField9Type"
                            formControlName="custom_field_9_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-item">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customField10">Custom Field 10</label>
                          <input type="text" class="form-control" id="customField10"
                            placeholder="Type custom field (short text)" formControlName="custom_field_10"
                            (keypress)="onlyChar($event)" />
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="customField10Checkbox"
                              formControlName="custom_field_10_visible" />
                            <label class="form-check-label" for="flexCheckDefault">
                              Visible on the frontend/for staff members.
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="customField10Type">Type</label>
                          <select class="form-select" aria-label="customField10Type"
                            formControlName="custom_field_10_type">
                            <option value="string">
                              string
                            </option>
                            <option value="date">
                              date
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>