<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="edit-staff-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1 d-flex align-items-center">
                  <h1 class="main-heading">Edit ID</h1>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                  <button
                    class="btn-primary-sm btn-grey shine cancel"
                    (click)="goToStaff()"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn-primary-sm btn-blue ml20 shine save"
                    (click)="onSubmit()"
                  >
                    Save
                  </button>
                </div>
              </div>
              <form [formGroup]="staffForm">
                <div
                  class="d-flex flex-wrap justify-content-between mt20 customForm"
                >
                  <div class="form-group form-item">
                    <label for="customField1">First Name <span class="text-red">*</span></label>
                    <input
                      type="text"
                      [ngClass]="{'error': staffForm.controls['first_name'].invalid && (staffForm.controls['first_name'].dirty || staffForm.controls['first_name'].touched), 'form-control':true }"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder="Type first name"
                      formControlName="first_name"
                      (keypress)="onlyChar($event)"
                    />
                    <div *ngIf="staffForm.controls['first_name'].invalid && (staffForm.controls['first_name'].dirty || staffForm.controls['first_name'].touched)" class="errorMsg">
                      <div *ngIf="staffForm.controls['first_name'].errors.required">
                        Name required!
                      </div>
                  </div>
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder="Type Last Name"
                      formControlName="last_name"
                      (keypress)="onlyChar($event)"
                    />
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">Mobile Number <span class="text-red">*</span></label>
                    <input
                      type="text"
                      [ngClass]="{'error': staffForm.controls['mobile'].invalid && (staffForm.controls['mobile'].dirty || staffForm.controls['mobile'].touched), 'form-control':true }"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder="Type Mobile Number"
                      formControlName="mobile"
                      maxlength="10"
                      minlength="10"
                      (keypress)="onlyNumbers($event)"
                    />
                    <div *ngIf="staffForm.controls['mobile'].invalid && (staffForm.controls['mobile'].dirty || staffForm.controls['mobile'].touched)" class="errorMsg">
                      <div *ngIf="staffForm.controls['mobile'].errors.required">
                        Mobile required!
                      </div>
                      <div *ngIf="staffForm.controls['mobile'].errors.minlength || staffForm.controls['mobile'].errors.maxlength">
                        Mobile Number must be 10 digit long.
                      </div>
                  </div>
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">Store Code <span class="text-red">*</span></label>
                    <mat-select
                      formControlName="store_id"
                      placeholder="Type Store Code"
                      #singleSelect
                      [ngClass]="{'error': staffForm.controls['store_id'].invalid && (staffForm.controls['store_id'].dirty || staffForm.controls['store_id'].touched), 'form-control':true }"
                      disableOptionCentering="true"
                    >
                      <mat-option class="custom-height">
                        <ngx-mat-select-search
                          [formControl]="storeFilterCtrl"
                          placeholderLabel="Search"
                        ></ngx-mat-select-search>
                      </mat-option>

                      <mat-option
                        *ngFor="let store of filteredStores"
                        [value]="store.id"
                      >
                        {{ store.store_code }}
                      </mat-option>
                    </mat-select>
                    <div *ngIf="staffForm.controls['store_id'].invalid && (staffForm.controls['store_id'].dirty || staffForm.controls['store_id'].touched)" class="errorMsg">
                      <div *ngIf="staffForm.controls['store_id'].errors.required">
                        Store code required!
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-item">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        formControlName="is_admin"
                        id="is_admin"
                      />
                      <label for="is_admin">
                        Is Admin ? 
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
