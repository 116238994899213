import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  errorMsg: any;
  loginForm: any;
  constructor(
    private router: Router,
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,

  ) {}
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      mobile: ['', [Validators.required]],
      value: ['', [Validators.required]],
      is_admin:true
    });
  }
  onSubmitLoginForm() {
    if (this.loginForm.invalid) {
      return;
    } else {
      this.apiService.otpValidate(this.loginForm.value).subscribe(
        (res: any) => {
          if (res['status'] === 'success') {
            this.errorMsg = '';
            localStorage.setItem('token', res['result'].token);
            this.router.navigate(['/campaign']);
          }else{
            if(this.loginForm.value.value === "9999"){
              this.toaster.error("I apologize for the inconvenience. To become an administrator, Kindly request system admin!");
            }else{
              this.toaster.error(`${res?.error?.message}`);

            }
          }
        },
        (err: any) => {
          if (err['status'] == 400) {
            this.errorMsg = err.error.message;
          }
          if (err) {
            this.errorMsg = err.error.message;
            this.toaster.error(`${err?.error?.message}`);
          }
        }
      );
    }
  }
}
