import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-edit-priority',
  templateUrl: './edit-priority.component.html',
  styleUrls: ['./edit-priority.component.scss']
})
export class EditPriorityComponent implements OnInit {
  id: any;
  name: any;

  constructor( private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private service: LoginService,
    private toaster: ToastrService,) { 
      this.id  = this.route.snapshot.params['id'];
      this.name  = this.route.snapshot.params['name'];
    }

    editPriorityForm = this.formBuilder.group({
      priorityname: ''
      });
  
    token: any;
    message: any;
  
    ngOnInit(): void {
      this.token = this.service.checkToken();
    }
  
    onSave(){
      const reqObj = {
        priority_name: this.editPriorityForm.get("priorityname")?.value
      }
  
      this.service.editPriorities(this.id, reqObj)
      .subscribe(
        (res: any) => {
         this.message = res && res.status == "success" ? res.result : res.message;
         if(res.status == "success"){
          this.router.navigate(['/priority-list']);
          this.toaster.success(res.result);
         }
      },
      (err:any) => {
        this.toaster.error(err.error.message);
      });
  
    }
  
    onCancel(){
      this.router.navigate(['/priority-list']);
    }
  
}
