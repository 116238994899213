<section class="globle-container">
  <div class="login-container">
    <div class="d-flex flex-wrap content-wrapper">
      <div class="left-pane"></div>
      <div class="right-pane">
        <div class="content-wrapper">
          <img
            class="login-logo"
            src="../../assets/images/pantaloons-logo-login.png"
          />
          <h1 class="mt20">Login</h1>
          <p>Please enter the username and password provided to you</p>

          <form
            class="custom-form"
            [formGroup]="loginForm"
            (ngSubmit)="onSubmitLoginForm()"
          >
            <div class="input-group mb-3">
              <span class="input-icon"
                ><img class="pr10" src="../../assets/images/userid-icon.png"
              /></span>

              <input
                type="text"
                class="form-control"
                id="till-id"
                placeholder="Mobile"
                formControlName="mobile"
              />
            </div>
            <div class="input-group mb-3">
              <span class="input-icon"
                ><img
                  class="pr10"
                  src="../../assets/images/password-icon-new.png"
              /></span>

              <input
                type="text"
                class="form-control"
                id="password"
                placeholder="PASSWORD"
                formControlName="value"
              />
            </div>
            <div *ngIf="errorMsg" class="error" style="color: red">
              {{ errorMsg }}
            </div>

            <button
              type="submit"
              class="btn btn-primary btn-block mt10 shine"
              [disabled]="!loginForm.valid"
            >
              Login
            </button>
          </form>
        </div>
        <p class="copyright-text text-center">
          Copyright © 2022. All rights reserved
        </p>
      </div>
      <div></div>
    </div>
  </div>
</section>
