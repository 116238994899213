<section class="globle-container">
    <div class="dashboar-container">
      <div class="d-flex flex-wrap dashboard-wrapper">
        <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
          <app-side-nav></app-side-nav>
        </div>
        <div class="right-panel">
          <router-outlet></router-outlet>
          <app-header-bar></app-header-bar>
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-campaign"
              role="tabpanel"
              aria-labelledby="v-pills-campaign-tab"
            >
              <div class="campain-container">
                <div class="header-div row m-0">
                  <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                    <button class="btn back pl20" (click)="goToBack()">
                      Back To Customer Uploads
                    </button>
                </div>
                  <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                    <h1 class="component-heading">Upload History</h1>
                  </div>
                   <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                    <p class="text-primary-new">Processing of the uploaded data is complete. Please check the upload history to view its status.</p>
                  </div>
                </div>
  
                <div class="table-container pt10">
                  <div class="custom-loader" *ngIf="!chunkData">
                    <div class="text-center loader-wrapper">
                      <div class="spinner-border text-primary-new" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
  
                  <div class="table-responsive border-radius table-fixed-height">
                    <table class="table table-borderless b-radius">
                      <thead>
                        <tr class="heading">
                          <th scope="col">ID</th>
                          <th scope="col">From Record</th>
                          <th scope="col">To Record</th>
                          <th scope="col">Total Size</th>
                          <th scope="col">No of Duplicate Records</th>
                          <th scope="col">No of Invalid Records</th>
                          <th scope="col">No of Inserted Records</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
  
                      <tbody
                        *ngFor="let eachChunk of chunkData">
                        <tr>
                          <td scope="col">{{eachChunk.id}}</td>
                          <td scope="col">{{eachChunk.fromRecord}}</td>
                          <td scope="col">{{eachChunk.toRecord}}</td>
                          <td scope="col">{{eachChunk.totalSize}}</td>
                          <td scope="col">{{eachChunk.noOfDuplicateRecord}}</td>
                          <td scope="col">{{eachChunk.noOfInvalidRecord}}</td>
                          <td scope="col">{{eachChunk.noOfRecordInserted}}</td>
                          <td scope="col">{{eachChunk.status}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div *ngIf="chunkData?.length < 1" class="text-center text-red p-3">
                No Data Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  