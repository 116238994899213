import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { environment as env } from '../../../environments/environment';


@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss'],
})
export class StaffListComponent implements OnInit {
  staffList: any;
  page: number = 1;
  pageSize = 6;
  total: number = 0;
  isUploading: boolean = true;
  count: number = 0;
  headers: any = [];
  fileName: any;
  constructor(
    private apiService: LoginService,
    private toaster: ToastrService,
    private router: Router
  ) { }
  token: any;
  isShown: boolean = false; // hidden by default
  url = env.staffUrl
  @ViewChild('file')
  myInputVariable!: ElementRef;

  ngOnInit() {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getStaff();
    }

  }
  pageChangeEvent(event: number) {
    this.page = event;
    if (this.searchKey) {
      this.searchData();
    } else {
      this.getStaff();
    }
  }
  getStaff() {

    this.apiService.getStaffList(this.pageSize, this.page, this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.staffList = res['result'].data;

          this.total = res['result'].count;

          for (const staff of this.staffList) {
            let lastLoginTime = `${moment(staff.lastLoginTime).format(
              'LT'
            )}, ${moment(staff.lastLoginTime).format('L')}`;
            staff.lastLoginTime = staff.lastLoginTime ? lastLoginTime : '-';
          }
        }
      },
      (err) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  getStaffId(id: any) {
    let data = {
      data: id,
    };
    this.router.navigate(['/staff', data]);
  }
  goToAdd() {
    this.router.navigate(['/add-staff']);
  }
  searchKey: any = '';
  searchData(resetPage = false) {
    if (resetPage) {
      this.page = 1;
    }
    this.apiService
      .search('staff', this.searchKey, this.pageSize, this.page, this.token)
      .subscribe(
        (res: any) => {
          if (res['status'] == 'success') {
            this.staffList = res['result'].data;
            this.total = res['result'].count;
            for (const staff of this.staffList) {
              let lastLoginTime = `${moment(staff.lastLoginTime).format(
                'LT'
              )}, ${moment(staff.lastLoginTime).format('L')}`;
              staff.lastLoginTime = staff.lastLoginTime ? lastLoginTime : '-';
            }
          }else{
            this.toaster.error(res?.error?.message);

          }
        },
        (err) => {
          this.toaster.error(err.error.message);
        }
      );
  }

  handleFileInput(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = event.target.files[0].name;
      this.isUploading = false;
      this.apiService.getSignedUrl(file).subscribe(
        (res:any) =>{
          this.apiService.uploadFileToS3(res.result?.putUrl,file).subscribe((uploadRes:any)=>{
            this.apiService.bulkInsertStaffData({url:res.result?.getUrl}).subscribe((bulkUploadRes:any)=>{
                if(bulkUploadRes.status === "success"){
                  this.isUploading = true;
                  this.toaster.success("Imported data Successfully");
                  this.getStaff();
                  if(bulkUploadRes.result){
                    this.apiService.downloadS3File(bulkUploadRes.result)
                  }
                  this.myInputVariable.nativeElement.value = '';
                }else{
                  this.isUploading = true;
                  this.myInputVariable.nativeElement.value = '';
                  this.toaster.error(bulkUploadRes?.error?.message);
      
                }
            },(err:any) => {
              console.log("error:::::",err)
              this.isUploading = true;
              this.myInputVariable.nativeElement.value = '';

            })
          },
          (err:any) => {
            console.log("error",err)
            this.isUploading = true;
            this.myInputVariable.nativeElement.value = '';

          })
        },
        (err:any) => {
          this.isUploading = true;
          this.myInputVariable.nativeElement.value = '';

        }
      )
    }
  }
}
