import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-customer-upload',
  templateUrl: './customer-upload.component.html',
  styleUrls: ['./customer-upload.component.scss']
})
export class CustomerUploadComponent implements OnInit {

 
  token: any;
  data: any;
  total: number = 0;
  page: number = 1;
  pageSize = 6;
  enableUserDropdown = false;
  headers: any;
  jsonData: any;
  fileName: any;
  filterForm: any;
  submittedCampaignForm: boolean = false;
  showDateErrorMsg: boolean = false;
  passwordNotMaching: boolean = false;
  campaignId:any
  constructor(
    private apiService: LoginService,
    private router: Router,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.campaignId = this.route.snapshot.params['data'];
  }
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getCustomerUploadByCampaignId();
    }
    this.filterForm = this.formBuilder.group({
      inactive: [null],
      active: [null],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  get campaignFormVal() {
    return this.filterForm.controls;
  }

  getUploadId(id:any){
    this.router.navigate([`/upload-history`,{ data:id,campaignId:this.campaignId }]);
  }


  downloadS3File = function(url:any){
    var a = document.createElement("a");
    a.href = url;
    a.download = "fileName";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

  getErrorFile(chunkData:any){
    this.apiService.getErrorFile(chunkData.id).subscribe((res:any) => {
      if(res['status'] === 'success' && res['result'] != "No Errors found!"){
        console.log("error File",res.result)
        this.downloadS3File(res.result)
        chunkData.errorFileLink = res.result
        
      }else{
        this.toaster.info(res.result);
      }
    },
    (err:any) => {
      this.toaster.error(err.error.message);
    })
  }

  goToBack() {
    let data = {
      data: this.campaignId
    };
    this.router.navigate(['/campaign-detail', data]);
  }
 
  isShown: boolean = false; // hidden by default

  getCustomerUploadByCampaignId() {
    this.apiService.getCustomerUploadsByCampaignId(this.campaignId).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.data = res['result'];
          this.total = this.data.length;
        }
      },
      (err) => {
        this.toaster.error(err.error.message);
      }
    );
  }

 
  showDropDown() {
    this.enableUserDropdown = !this.enableUserDropdown;
  }

  
  download() {
    this.apiService.downloadFile(this.jsonData, this.fileName, this.headers);
  }

}
