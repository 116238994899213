import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs/internal/observable/of';



@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token: any;
  constructor(private http: HttpClient,private router: Router,private toaster: ToastrService) {
    this.token = localStorage.getItem('token');
  }

  interCeptor = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    withCredentials: true,
  };


  confirmBox(){
    Swal.fire({
      title: 'Sorry, Your token expired!',
      text: 'We\'ll need to re-login to refresh the token.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result:any) => {
      this.router.navigateByUrl('/login');
      localStorage.clear();
    })
  }

 

  get(url: any, params: any = ""): Observable<any> {
    const token:any = localStorage.getItem('token');
    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token': token
      }),
      withCredentials: true,
    };

    return this.http.get<any>(env.AUTH_BASE_URL + url, this.interCeptor).pipe(
      catchError(error => {
          let errorMsg: string = error?.error?.message;

          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  post(url: any, body: any): Observable<any> {
    const token:any = localStorage.getItem('token');
    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token': token ? token : "",
      }),
      withCredentials: true,
    };
    return this.http.post<any>(env.AUTH_BASE_URL + url, body,this.interCeptor).pipe(
      catchError(error => {
          let errorMsg: string = "";
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  put(url:any,body:any) : Observable<any> {
    const token:any = localStorage.getItem('token');

    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token': token,
      }),
      withCredentials: true,
    };
    return this.http.put(
      env.AUTH_BASE_URL + url,
      body,
      this.interCeptor
    ).pipe(
      catchError(error => {
          let errorMsg: string = "";
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  delete(url:any) : Observable<any> {
    const token:any = localStorage.getItem('token');

    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token':  token,
      }),
      withCredentials: true,
    };
    return this.http.delete(
      env.AUTH_BASE_URL + url,
      this.interCeptor
    ).pipe(
      catchError(error => {
          let errorMsg: string = "";
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

}
