import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CampaignComponent } from './campaign/campaign.component';
import { HttpClientModule } from '@angular/common/http';
import { CampaignDetailsComponent } from './campaign/campaign-details/campaign-details.component';
import { AddCampaignComponent } from './campaign/add-campaign/add-campaign.component';
import { EditCampaignComponent } from './campaign/edit-campaign/edit-campaign.component';
import { PriorityListComponent } from './priority/priority-list/priority-list.component';
import { AddPriorityComponent } from './priority/add-priority/add-priority.component';
import { EditPriorityComponent } from './priority/edit-priority/edit-priority.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from './services/api.service';
import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { EditStaffComponent } from './staff/edit-staff/edit-staff.component';
import { StaffDetailsComponent } from './staff/staff-details/staff-details.component';
import { StoreListComponent } from './store/store-list/store-list.component';
import { AddStoreComponent } from './store/add-store/add-store.component';
import { EditStoreComponent } from './store/edit-store/edit-store.component';
import { StoreDetailsComponent } from './store/store-details/store-details.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { NgxPaginationModule } from 'ngx-pagination';


import { SortPipe } from './shared/pipes/sort.pipe';
import { UploadHistoryComponent } from './campaign/upload-history/upload-history.component';
import { CustomerUploadComponent } from './campaign/customer-upload/customer-upload.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CampaignComponent,
    CampaignDetailsComponent,
    AddCampaignComponent,
    EditCampaignComponent,
    PriorityListComponent,
    AddPriorityComponent,
    EditPriorityComponent,
    StaffListComponent,
    AddStaffComponent,
    EditStaffComponent,
    StaffDetailsComponent,
    StoreListComponent,
    AddStoreComponent,
    EditStoreComponent,
    StoreDetailsComponent,
    SideNavComponent,
    HeaderBarComponent,
    SortPipe,
    UploadHistoryComponent,
    CustomerUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      positionClass: 'toast-bottom-right',
    }),
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    FormsModule,
    NgxPaginationModule,
  ],
  providers: [ApiService,SortPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
