<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="priority-container">
              <div class="header-div row m-0">
                <div class="col-lg-6 col-sm-12 p-0">
                  <h1 class="component-heading">Priority Information</h1>
                  <p class="component-para">
                    Lorem ipsum dolor amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div
                  class="col-lg-6 col-sm-12 p-0 d-flex justify-content-end action-btns"
                >
                  <div class="search-bar mr20">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-serach"
                    />
                    <button class="search-btn" type="button">Serach</button>
                  </div>
                  <button
                    class="btn-blue btn-primary-sm shine"
                    (click)="addPriority()"
                  >
                    Add Priority
                  </button>
                </div>
              </div>
              <div class="table-container pt10">
                <div class="custom-loader" *ngIf="!priorities">
                  <div class="text-center loader-wrapper">
                    <div class="spinner-border text-primary-new" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>
                <table class="table table-borderless b-radius">
                  <thead>
                    <tr class="heading">
                      <th class="col-10" scope="col">Priority</th>
                      <th class="col-2" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let priority of priorities">
                    <tr>
                      <td class="col-10">
                        <span class="priority-name">
                          {{ priority.priority_name }}</span
                        >
                      </td>
                      <td class="col-2">
                        <span
                          class="edit-btn pl30"
                          (click)="editPriority(priority)"
                          >Edit</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <nav aria-label="Page navigation" class="custom-pagination">
                  <ul class="pagination pagination-gap">
                    <li class="page-item">
                      <a class="page-link btn-arrow-left">Previous</a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link"><span>1</span></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><span>2</span></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><span>3</span></a>
                    </li>
                    <li class="page-item disabled">
                      <a class="page-link"><span>...</span></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><span>10</span></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link btn-arrow-right" tabindex="-1"
                        >Next</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
