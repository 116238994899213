<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div
        class="left-panel"
        id="test"
        [ngClass]="isShown ? 'menuOpen' : 'menuClose'"
      >
        <img
          class="close-icon"
          (click)="toggleShow()"
          src="../../assets/images/close-icon.png"
        />
        <div class="logo d-flex align-items-center justify-content-center">
          <img src="../../assets/images/logo-white.png" />
        </div>
        <div class="pl20 pr30 pt40">
          <div
            class="nav flex-column nav-pills text-align-left"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              (click)="toggleShow()"
              class="nav-link active"
              id="v-pills-campaign-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-campaign"
              type="button"
              role="tab"
              aria-controls="v-pills-campaign"
              aria-selected="true"
            >
              <span class="nav-icon"
                ><img src="../../assets/images/campaign-icon.png" /></span
              >Campaign
            </button>
            <button
              class="nav-link"
              id="v-pills-staff-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-staff"
              type="button"
              role="tab"
              aria-controls="v-pills-staff"
              aria-selected="false"
            >
              <span class="nav-icon"
                ><img src="../../assets/images/staff-icon.png" /></span
              >Staff
            </button>
            <button
              class="nav-link"
              id="v-pills-store-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-store"
              type="button"
              role="tab"
              aria-controls="v-pills-store"
              aria-selected="false"
            >
              <span class="nav-icon"
                ><img src="../../assets/images/store-icon.png" /></span
              >Store
            </button>
            <button
              (click)="toggleShow()"
              class="nav-link"
              id="v-pills-priority-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-priority"
              type="button"
              role="tab"
              aria-controls="v-pills-priority"
              aria-selected="false"
            >
              <span class="nav-icon"
                ><img src="../../assets/images/priority-icon.png" /></span
              >Priority
            </button>
          </div>
        </div>
      </div>
      <div class="right-panel">
        <div class="top-bar w-100 sticky-top">
          <button (click)="toggleShow()" class="hamburger-menu"></button>
          <div class="mobile-logo">
            <img src="../../assets/images/logo-white.png" />
          </div>
          <div class="logout mr20" (click)="logOut()">Logout</div>
        </div>

        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <app-campaign></app-campaign>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-staff"
            role="tabpanel"
            aria-labelledby="v-pills-staff-tab"
          >
            <app-staff-list></app-staff-list>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-store"
            role="tabpanel"
            aria-labelledby="v-pills-store-tab"
          >
            <app-store-list></app-store-list>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-priority"
            role="tabpanel"
            aria-labelledby="v-pills-priority-tab"
          >
            <app-priority-list></app-priority-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
