<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="store-list-container">
              <div class="header-div row m-0">
                <div class="col-lg-6 col-sm-12 p-0">
                  <h1 class="component-heading">Store Information</h1>
                  <p class="component-para">
                    Lorem ipsum dolor amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div
                  class="
                    col-lg-6 col-sm-12
                    p-0
                    d-flex
                    justify-content-end
                    action-btns
                  "
                >
                  <div class="search-bar mr20">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-serach"
                      [(ngModel)]="searchKey"
                      (keyup)="searchData(true)"
                    />
                    <button
                      class="search-btn"
                      type="button"
                      (click)="searchData(true)"
                    >
                      Serach
                    </button>
                  </div>
                  <button
                    class="btn-blue btn-primary-sm shine"
                    (click)="addStore()"
                  >
                    Add Store
                  </button>
                </div>
              </div>
              <div class="table-container pt10">
                <div class="custom-loader" *ngIf="!storeList">
                  <div class="text-center loader-wrapper">
                    <div class="spinner-border text-primary-new" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>
                <div *ngIf="storeList?.length<1">
                  No Data Found
                </div>
                <div class="table-responsive border-radius">
                  <table class="table table-borderless b-radius">
                    <thead>
                      <tr class="heading">
                        <th class="col-2" scope="col">Store Code</th>
                        <th class="col-2" scope="col">Store Name</th>
                        <th class="col-2" scope="col">Zone</th>
                        <th class="col-2" scope="col">City</th>
                        <th class="col-2" scope="col">Zipcode</th>
                        <th class="col-2" scope="col">State</th>
                      </tr>
                    </thead>
                    <tbody
                      *ngFor="
                        let store of storeList
                          | paginate
                            : {
                                itemsPerPage: 6,
                                currentPage: page,
                                totalItems: total
                              }
                      "
                    >
                      <tr>
                        <td class="col-2" (click)="getStaffId(store.id)">
                          <span class="store-code hyperLink">{{ store.store_code }}</span>
                        </td>

                        <td class="col-2">
                          <span class="store-name">{{ store.store_name }}</span>
                        </td>
                        <td class="col-2">
                          <span class="zone">{{ store.zone }}</span>
                        </td>

                        <td class="col-2">
                          <span class="city">{{ store.city }}</span>
                        </td>
                        <td class="col-2">
                          <span class="zipcode">{{ store.zipCode }}</span>
                        </td>
                        <td class="col-2">
                          <span class="state">{{ store.state }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <pagination-controls
                  class="custom-pagination"
                  (pageChange)="pageChangeEvent($event)"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
