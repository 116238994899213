import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from './login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend-admin';
  token: any;

  constructor(private apiService: LoginService) {}

  ngOnInit() {}
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    // event.preventDefault();
    // event.returnValue = '';
    this.apiService.logout().subscribe();
    // localStorage.clear();
  }
}
