import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit {

  storeId: any;
  store: any;
  token: any
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: LoginService,
    private toaster: ToastrService
  ) {
    this.storeId = this.route.snapshot.params['data'];
  }
  isShown: boolean = false; // hidden by default
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if(this.token){
    this.getStore();
    }
  }
  getStore() {
    this.apiService.getStore(this.storeId,  this.token).subscribe(
      (res: any) => {
        if (res['status'] == 'success') {
          this.store = res['result'];
        }
      },
      (err) => {
        this.toaster.error(err.message);
      }
    );
  }
  displayStyle = 'none';
  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }
  goToStore() {
    this.router.navigate(['/store-list']);
  }
  editStore(){
    let data = {
      data: this.storeId,
    };
    this.router.navigate(['/edit-store', data])
  }

}
