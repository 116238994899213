import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {
  constructor(private router: Router, private apiService: LoginService) {}
  token = localStorage.getItem('token');
  mobileOnly: boolean = false; // hidden by default
  activatePriority: boolean = false; // hidden by default
  activateCampaign: boolean = false; // hidden by default
  activateStaff: boolean = false; // hidden by default
  activateStore: boolean = false; // hidden by default
  ngOnInit(): void {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.mobileOnly = true;
    }
    if (this.router.url.includes('campaign')) {
      this.activateCampaign = true;
    } else if (this.router.url.includes('staff')) {
      this.activateStaff = true;
    } else if (this.router.url.includes('store')) {
      this.activateStore = true;
    } else if (this.router.url.includes('priority')) {
      this.activatePriority = true;
    } else {
      console.warn('default');
    }
  }
  logOut() {
    this.apiService.logout().subscribe((res: any) => {
      this.router.navigateByUrl('/login');
    });
    localStorage.clear();
  }

  isShown: boolean = false; // hidden by default

  toggleShow() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isShown = !this.isShown;
    } else {
    }
  }
}
