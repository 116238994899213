<section class="globle-container">
  <div class="dashboar-container">
    <div class="d-flex flex-wrap dashboard-wrapper">
      <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
        <app-side-nav></app-side-nav>
      </div>
      <div class="right-panel">
        <router-outlet></router-outlet>
        <app-header-bar></app-header-bar>
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-campaign"
            role="tabpanel"
            aria-labelledby="v-pills-campaign-tab"
          >
            <div class="add-store-container">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1 d-flex align-items-center">
                  <h1 class="main-heading">Add Store</h1>
                </div>
                <div class="flex-grow-1 d-flex flex-wrap buttons-gorup">
                  <button
                    class="btn-primary-sm btn-grey shine cancel"
                    (click)="goToStore()"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn-primary-sm btn-blue ml20 shine save"
                    (click)="saveStore()"
                    [disabled]="storeForm.invalid"
                  >
                    Save
                  </button>
                </div>
              </div>

              <form [formGroup]="storeForm">
                <div
                  class="
                    d-flex
                    flex-wrap
                    justify-content-between
                    mt20
                    customForm
                  "
                >
                  <div class="form-group form-item">
                    <label for="customField1">Store Code <span class="text-red">*</span></label>
                    <input
                      type="text"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder="Type Store Code"
                      formControlName="store_code"
                      (keypress)="onlyAlphaNumeric($event)"
                      autocomplete="off"
                      [ngClass]="{'error': storeForm.controls['store_code'].invalid && (storeForm.controls['store_code'].dirty || storeForm.controls['store_code'].touched), 'form-control':true }"

                    />
                    <div *ngIf="storeForm.controls['store_code'].invalid && (storeForm.controls['store_code'].dirty || storeForm.controls['store_code'].touched)" class="errorMsg ">
                      <div *ngIf="storeForm.controls['store_code'].errors.required">
                          Store Code required
                      </div>
                  </div>
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">Store Name <span class="text-red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder="Type Store Name"
                      formControlName="store_name"
                      (keypress)="onlyChar($event)"
                      autocomplete="off"
                      [ngClass]="{'error': storeForm.controls['store_name'].invalid && (storeForm.controls['store_name'].dirty || storeForm.controls['store_name'].touched), 'form-control':true }"

                    />
                    <div *ngIf="storeForm.controls['store_name'].invalid && (storeForm.controls['store_name'].dirty || storeForm.controls['store_name'].touched)" class="errorMsg">
                      <div *ngIf="storeForm.controls['store_name'].errors.required">
                          Store Name required
                      </div>
                  </div>
                  <div *ngIf="storeForm.controls['store_name'].invalid && (storeForm.controls['store_name'].dirty || storeForm.controls['store_name'].touched)" class="errorMsg">
                    <div *ngIf="storeForm.controls['store_name'].errors.pattern">
                         Store name should not have a special character or space at the end.
                    </div>
                </div>
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">Zone <span class="text-red">*</span></label>
                    <select
                      class="form-select"
                      aria-label="Zone"
                      formControlName="zone"
                      aria-placeholder="Choose Zone"
                      [ngClass]="{'error': storeForm.controls['zone'].invalid && (storeForm.controls['zone'].dirty || storeForm.controls['zone'].touched), 'form-select':true }"

                    >
                      <option *ngFor="let zone of allZone" [value]="zone">
                        {{ zone }}
                      </option>
                    </select>
                    <div *ngIf="storeForm.controls['zone'].invalid && (storeForm.controls['zone'].dirty || storeForm.controls['zone'].touched)" class="errorMsg">
                      <div *ngIf="storeForm.controls['zone'].errors.required">
                          Zone required
                      </div>
                  </div>
                  
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">Zipcode <span class="text-red">*</span></label>
                    <input
                      type="text"
                      [ngClass]="{'error': storeForm.controls['zipCode'].invalid && (storeForm.controls['zipCode'].dirty || storeForm.controls['zipCode'].touched), 'form-control':true }"
                      id="customField1"
                      aria-describedby="customField1"
                      placeholder="Type Zipcode"
                      formControlName="zipCode"
                      maxlength="6"
                      minlength="6"
                      (keypress)="onlyNumbers($event)"
                      autocomplete="off"
                    />
                    <div *ngIf="storeForm.controls['zipCode'].invalid && (storeForm.controls['zipCode'].dirty || storeForm.controls['zipCode'].touched)" class="errorMsg">
                      <div *ngIf="storeForm.controls['zipCode'].errors.required">
                        Zip code required
                      </div>
                      <div *ngIf="storeForm.controls['zipCode'].errors.minlength || storeForm.controls['zipCode'].errors.maxlength">
                        Zip code must be 6 digit long.
                      </div>
                  </div>
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">State <span class="text-red">*</span></label>
                    <select
                      class="form-select"
                      aria-label="state"
                      formControlName="state"
                      [ngClass]="{'error': storeForm.controls['state'].invalid && (storeForm.controls['state'].dirty || storeForm.controls['state'].touched), 'form-select':true }"

                    >
                      <option *ngFor="let state of allStates" [value]="state">
                        {{ state }}
                      </option>
                    </select>
                    <div *ngIf="storeForm.controls['state'].invalid && (storeForm.controls['state'].dirty || storeForm.controls['state'].touched)" class="errorMsg">
                      <div *ngIf="storeForm.controls['state'].errors.required">
                        State required
                      </div>
                  </div>
                  </div>
                  <div class="form-group form-item">
                    <label for="customField1">City <span class="text-red">*</span></label>
                    <select
                      class="form-select"
                      aria-label="city"
                      formControlName="city"
                      [ngClass]="{'error': storeForm.controls['city'].invalid && (storeForm.controls['city'].dirty || storeForm.controls['city'].touched), 'form-select':true }"

                    >
                      <option *ngIf="!storeForm.controls['state'].value">Select State to render cities..</option>
                      <option *ngIf="storeForm.controls['state'].value">Select City</option>
                      <option *ngFor="let city of allStatesCity[storeForm.controls['state'].value] | sort:'asc':'city'" [value]="city.city">
                        {{ city.city }}
                      </option>
                    </select>
                    <div *ngIf="storeForm.controls['city'].invalid && (storeForm.controls['city'].dirty || storeForm.controls['city'].touched)" class="errorMsg">
                      <div *ngIf="storeForm.controls['city'].errors.required">
                        City required
                      </div>
                  </div>
                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
