import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService as AuthGuard } from '../app/authguard.service';
import { CampaignDetailsComponent } from './campaign/campaign-details/campaign-details.component';
import { AddCampaignComponent } from './campaign/add-campaign/add-campaign.component';
import { EditCampaignComponent } from './campaign/edit-campaign/edit-campaign.component';
import { PriorityListComponent } from './priority/priority-list/priority-list.component';
import { AddPriorityComponent } from './priority/add-priority/add-priority.component';
import { EditPriorityComponent } from './priority/edit-priority/edit-priority.component';
import { CampaignComponent } from './campaign/campaign.component';
import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffDetailsComponent } from './staff/staff-details/staff-details.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { EditStaffComponent } from './staff/edit-staff/edit-staff.component';
import { StoreListComponent } from './store/store-list/store-list.component';
import { StoreDetailsComponent } from './store/store-details/store-details.component';
import { AddStoreComponent } from './store/add-store/add-store.component';
import { EditStoreComponent } from './store/edit-store/edit-store.component';
import { UploadHistoryComponent } from './campaign/upload-history/upload-history.component';

import { CustomerUploadComponent } from './campaign/customer-upload/customer-upload.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'campaign-detail',
    component: CampaignDetailsComponent,
  },
  {
    path: 'add-campaign-detail',
    component: AddCampaignComponent,
  },
  {
    path: 'edit-campaign-detail',
    component: EditCampaignComponent,
  },
  {
    path: 'priority-list',
    component: PriorityListComponent,
  },
  {
    path: 'add-priority',
    component: AddPriorityComponent,
  },
  {
    path: 'edit-priority',
    component: EditPriorityComponent,
  },
  {
    path: 'campaign',
    component: CampaignComponent,
  },
  {
    path: 'staff-list',
    component: StaffListComponent,
  },
  {
    path: 'staff',
    component: StaffDetailsComponent,
  },
  {
    path: 'add-staff',
    component: AddStaffComponent,
  },
  {
    path: 'edit-staff',
    component: EditStaffComponent,
  },
  {
    path: 'store-list',
    component: StoreListComponent,
  },
  {
    path: 'store',
    component: StoreDetailsComponent,
  },
  {
    path: 'add-store',
    component: AddStoreComponent,
  },
  {
    path: 'edit-store',
    component: EditStoreComponent,
  },
  {
    path: 'upload-history',
    component: UploadHistoryComponent,
  },
  {
    path: 'customer-upload',
    component: CustomerUploadComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
