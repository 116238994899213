import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-add-priority',
  templateUrl: './add-priority.component.html',
  styleUrls: ['./add-priority.component.scss']
})
export class AddPriorityComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: LoginService,
    private toaster: ToastrService,
    private service: LoginService
  ) { }

  addPriorityForm = this.formBuilder.group({
    priorityname: ''
  });

  token: any;

  ngOnInit(): void {
    this.token = this.service.checkToken();
  }

  onSave() {
    const reqObj = {
      priority_name: this.addPriorityForm.get("priorityname")?.value
  }

this.apiService.addPriorities(reqObj)
  .subscribe(
    (res: any) => {
      if (res.status == "success") {
        this.router.navigate(['/priority-list']);
        this.toaster.success(res.result);
      }
    },
    (err:any) => {
      this.toaster.error(err.error.message);
    });
  }

  onCancel() {
    this.router.navigate(['/priority-list']);
  }

}
