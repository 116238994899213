import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { UntypedFormControl } from '@angular/forms';
import { switchMap, tap } from 'rxjs';
@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.scss'],
})
export class EditStaffComponent implements OnInit {
  staffId: any;
  staff: any;
  staffForm: any;
  stores: any;
  storeIds: any;
  token: any;
  constructor(
    private route: ActivatedRoute,
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.staffId = this.route.snapshot.params['data'];
  }

  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    if (this.token) {
      this.getStaff();
      this.getStores();
    }
    this.staffForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: [''],
      mobile: ['', Validators.required],
      store_id: [''],
      is_admin:false
    });
   
    // listen for search field value changes
    this.storeFilterCtrl.valueChanges.subscribe(() => {
      this.filterStores();
    });
  }
  isShown: boolean = false; // hidden by default
  getStaff() {
    this.apiService
      .getStaff(this.staffId, this.token)
      .pipe(
        tap((res: any) => {
          if (res['status'] == 'success') {
            this.staff = res['result'];
            this.staffForm.controls['first_name'].setValue(
              this.staff['first_name']
            );
            this.staffForm.controls['last_name'].setValue(
              this.staff['last_name']
            );
            this.staffForm.controls['mobile'].setValue(this.staff['mobile']);
           
            this.staffForm.controls['is_admin'].setValue(
              this.staff['is_admin']
            );
          }
        }),
        switchMap(() => this.getStores())
      )
      .subscribe(
        (res: any) => {
          if (res['status'] === 'success') {
            this.stores = res['result'].data;
            this.filteredStores = [...this.stores];
            const storeId = this.stores.find((eachStore: any) => eachStore.store_code === this.staff['store_code'])
            this.staffForm.controls['store_id'].setValue(
              storeId?.id
            );
            
          }
        },
        (err) => {
          this.toaster.error(err.message);
        }
      );
  }
  getStores() {
    return this.apiService.getStoreListStaff(true, this.token);
  }
  changeCity(e: any) {
    this.staffForm.controls['store_id'].setValue(e.target.value);
  }
  onSubmit() {
    this.apiService
      .editStaff(this.staffId, this.staffForm.value, this.token)
      .subscribe(
        (res: any) => {
          if (res['status'] === 'success') {
            this.toaster.success(`${res['result']}`);
            this.goToStaff();
          }else{
            console.log(res,"in else")
            this.toaster.error(`${res.error?.['message']}`);
          }
        },
        (err) => {
          this.toaster.error(`${err.error.message}`);
        }
      );
  }
  goToStaff() {
    let data = {
      data: this.staffId,
    };
    this.router.navigate(['/staff', data]);
  }
  onlyNumbers(ev: any) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  /** control for the MatSelect filter keyword */
  public storeFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of stores filtered by search keyword */
  public filteredStores: any = [];

  protected filterStores() {
    if (!this.stores) {
      return;
    }
    // get the search keyword
    let search = this.storeFilterCtrl.value;
    if (!search) {
      this.filteredStores = this.stores;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the stores
    this.filteredStores = this.stores.filter(
      (store: any) => store.store_code.toLowerCase().indexOf(search) > -1
    );
    
  }
  onlyChar(ev: any) {
    let onlynum = /^[a-zA-Z ]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
}
