<section class="globle-container">
    <div class="dashboar-container">
      <div class="d-flex flex-wrap dashboard-wrapper">
        <div class="left-panel" [ngClass]="isShown ? 'menuOpen' : 'menuClose'">
          <app-side-nav></app-side-nav>
        </div>
        <div class="right-panel">
          <router-outlet></router-outlet>
          <app-header-bar></app-header-bar>
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-campaign"
              role="tabpanel"
              aria-labelledby="v-pills-campaign-tab"
            >
              <div class="campain-container">
                
                <div class="header-div row m-0">
                    <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                        <button class="btn back pl20" (click)="goToBack()">
                          Back To Campaign Details
                        </button>
                    </div>
                  <div class="col-lg-6 col-sm-12 col-md-12 p-0">
                    <h1 class="component-heading">Customer Uploads</h1>
                  </div>
                 
                </div>
  
                <div class="table-container pt10">
                  <div class="custom-loader" *ngIf="!data">
                    <div class="text-center loader-wrapper">
                      <div class="spinner-border text-primary-new" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
  
                  <div class="table-responsive border-radius table-fixed-height">
                    <table class="table table-borderless b-radius">
                      <thead>
                        <tr class="heading">
                          <th scope="col">ID</th>
                          <th scope="col">File</th>
                          <th scope="col">Total record</th>
                          <th scope="col">Total Error record</th>
                          <th scope="col">Uploaded By</th>
                          <th scope="col">Error File</th>
                          <th scope="col">Chunk History</th>
                        </tr>
                      </thead>
  
                      <tbody
                        *ngFor="let eachChunk of data">
                        <tr>
                          <td scope="col">{{eachChunk.id}}</td>
                          <td scope="col"><a *ngIf="eachChunk.fileLink" [href]="eachChunk.fileLink">Download Uploaded File</a></td>
                          <td scope="col">{{eachChunk.totalSize}}</td>
                          <td scope="col">{{eachChunk.totalErrorSize}}</td>
                          <td scope="col">{{eachChunk.uploaded_by}}</td>
                          <td scope="col">
                            <a *ngIf="eachChunk.errorFileLink" [href]="eachChunk.errorFileLink">Download Error File</a>
                            <a *ngIf="!eachChunk.errorFileLink" class="hyperLink" (click) = "getErrorFile(eachChunk)">Error File</a>
                          </td>
                          <td scope="col" class="hyperLink" (click)="getUploadId(eachChunk.id)">View</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div *ngIf="data?.length < 1" class="text-center text-red p-3">
                No Data Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  