import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { stateCityNames } from '../../stateCity';

@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.scss'],
})
export class AddStoreComponent implements OnInit {

  storeForm: any;
  stores: any;
  allStatesCity:any = stateCityNames
  allStates = this.sortData(Object.keys(stateCityNames))
  allZone = ['EAST', 'WEST', 'NORTH', 'SOUTH'];
  token: any;
  constructor(
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private router: Router,
    private sortPipe: SortPipe
  ) {}
  isShown: boolean = false; // hidden by default
  //  : removed this validation
  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    this.storeForm = this.formBuilder.group({
      zone: ['', Validators.required],
      store_code: ['', Validators.required],
      store_name: ['', [Validators.required,Validators.pattern(/^[a-zA-Z](?:[a-zA-Z_\- ]*[a-zA-Z])?$/)]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
    });
  }
  sortData(toSortArray : any) {
    return toSortArray.sort(function (a:any, b:any) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }
  saveStore() {
    this.apiService.addStore(this.storeForm.value, this.token).subscribe(
      (res: any) => {
        if (res['status'] === 'success') {
          this.toaster.success(`${res['result']}`);
          this.goToStore();
        }else{
          console.log(res,"in else")
          this.toaster.error(`${res.error?.['message']}`);
        }
      },
      (err) => {
        console.log("error here",err)
        this.toaster.error(`${err.error.message}`);
      }
    );
  }
  goToStore() {
    this.router.navigate(['/store-list']);
  }
  onlyNumbers(ev: any) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyChar(ev: any) {
    let onlynum = /^[a-zA-Z_\- ]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyAlphaNumeric(event: any) {
    let onlynum = /^[A-Za-z0-9]*$/;
    if (!onlynum.test(event.key)) {
      event.preventDefault();
    }
  }
}
